import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { register } from '../../lib/fetch/auth';
import { upload } from '../../lib/fetch/files';
import { useAlert } from '../../providers/AlertProvider';
import ActivationRequestPresentational from './ActivationRequestPresentational';
import { IUser } from '../../lib/interfaces';
import { filterFiles } from '../../lib/utils';

enum STRAPI_REGISTRATION_ERROR_MESSAGES {
  EmailAlreadyTaken = 'Email is already taken',
}
const { REACT_APP_FILES_PATH_PREFIX } = process.env;
const ACCEPTED_FILES = Object.freeze([
  'image/png',
  'image/jpeg',
  'application/pdf',
  'text/plain',
]);
const MAX_FILES = 5;

const ActivationRequest = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const navigate = useNavigate();

  const onRegister = async (user: IUser) => {
    setLoading(true);
    const uuid = uuidv4();
    const { error, data: responseData } = await register({
      ...user,
      username: user.email,
      uuid,
      password: new Array(5)
        .fill(null)
        .map(() => Math.floor(Math.random() * 100 * 100))
        .join(''),
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      if (message === STRAPI_REGISTRATION_ERROR_MESSAGES.EmailAlreadyTaken) {
        message = t('STEP5_EMAIL_ALREADY_TAKEN');
      }
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    const { user: createdUser } = responseData || {};
    const { id: refId } = createdUser || {};
    const path = REACT_APP_FILES_PATH_PREFIX
      ? `${REACT_APP_FILES_PATH_PREFIX}/${uuid}`
      : undefined;
    if (refId && files.length)
      await upload({
        files,
        refId,
        ref: 'user',
        field: 'documents',
        path,
      });
    setLoading(false);
    navigate('/thank-you-registration');
  };

  const onFileChange = (newFiles: File[]) => {
    setFiles((currentFiles: File[]) => {
      const filteredFiles = filterFiles(
        newFiles,
        {
          acceptedTypes: ACCEPTED_FILES,
          maxSizeMB: 10,
          unsupportedFileCallback: (file: File, messageKey: string) =>
            dispatchAlertChange({
              open: true,
              message: t(messageKey, { fileName: file.name }),
            }),
        },
        currentFiles
      );
      if (currentFiles.length + filteredFiles.length > MAX_FILES) {
        dispatchAlertChange({
          open: true,
          message: t('STEP4_TOO_MANY_FILES', {
            maxFiles: MAX_FILES,
          }),
        });
        return currentFiles;
      }
      return [...currentFiles, ...filteredFiles];
    });
  };

  const onFileDelete = (fileToDelete: File) => {
    setFiles((currentFiles: File[]) =>
      currentFiles.filter((currentFile) => currentFile !== fileToDelete)
    );
  };

  return (
    <ActivationRequestPresentational
      loading={loading}
      files={files}
      acceptedFiles={ACCEPTED_FILES}
      onFileChange={onFileChange}
      onFileDelete={onFileDelete}
      onRegister={onRegister}
    />
  );
};

export default ActivationRequest;
