import { classNames } from '../../lib/utils';
import Spinner from '../Spinner';

interface IPrimaryButton {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const PrimaryButton = ({
  text,
  type = 'button',
  onClick = () => {},
  disabled = false,
  loading = false,
}: IPrimaryButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        'flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 uppercase',
        disabled ? 'cursor-not-allowed' : 'hover:bg-red-700'
      )}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default PrimaryButton;
