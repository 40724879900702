import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { classNames } from '../../lib/utils';
import { ITab } from '.';

interface ITabBarBig {
  tabs: ITab[];
}

const TabBarBig: FC<ITabBarBig> = (props) => {
  const { tabs } = props;
  const { t } = useTranslation('PRODUCTS');
  const [, dispatchAlertChange] = useAlert();
  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name || tabs[0].name}
          onChange={(event) => {
            const selectedTab = tabs[+event.target.value];
            selectedTab.onClick
              ? selectedTab.onClick()
              : dispatchAlertChange({
                  open: true,
                  type: AlertType.Info,
                  message: t('COMMON:AVAILABLE_SOON'),
                });
          }}
        >
          {tabs
            .filter((t) => !t.hidden)
            .map((tab, index) => (
              <option key={tab.name} value={index}>
                {tab.name}
              </option>
            ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs
              .filter((t) => !t.hidden)
              .map((tab, index) => (
                <div
                  key={tab.name}
                  className={classNames(
                    tab.current
                      ? 'border-red-500 text-red-600'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    'flex cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => {
                    tab.onClick
                      ? tab.onClick()
                      : dispatchAlertChange({
                          open: true,
                          type: AlertType.Info,
                          message: t('COMMON:AVAILABLE_SOON'),
                        });
                  }}
                >
                  {tab.name}
                  {tab.count !== undefined && (
                    <span
                      className={classNames(
                        tab.current
                          ? 'bg-red-100 text-red-600'
                          : 'bg-gray-100 text-gray-900',
                        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                      )}
                    >
                      {tab.count || 0}
                    </span>
                  )}
                </div>
              ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default TabBarBig;
