import axios, { AxiosResponse } from 'axios';

const { REACT_APP_ORDER_BILLING_ADDRESSES_URL } = process.env;

export interface IOrderBillingAddressCreate {
  title: string;
  name: string;
  phone?: string;
  addressFirstLine: string;
  addressSecondLine?: string;
  postalCode: string;
  municipality: string;
  province?: string;
  country: string;
  users_permissions_user: number;
}

export interface IOrderBillingAddressCreateResult {
  id: number;
  attributes: IOrderBillingAddressCreate;
}

const create = async (
  token: string,
  orderBillingAddress: IOrderBillingAddressCreate
) => {
  const response = await axios.post<{ data: IOrderBillingAddressCreateResult }>(
    REACT_APP_ORDER_BILLING_ADDRESSES_URL!,
    { data: orderBillingAddress },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
