import { useTranslation } from 'react-i18next';

export default function UnavailableBadge() {
  const { t } = useTranslation('PRODUCTS');

  return (
    <div>
      <span className="inline-flex items-center text-sm font-medium text-red-700">
        <div className="h-3 w-3 bg-red-700 mr-2 rounded-full" />
        {t('UNAVAILABLE')}
      </span>
    </div>
  );
}
