import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { IStrapiFile } from '../../../../lib/interfaces';
import { XIcon } from '@heroicons/react/solid';
import { GreyButton, PrimaryButton } from '../../../../components/Buttons';

interface IDeleteFile {
  loading: boolean;
  file: IStrapiFile;
  onFileDelete: (_: IStrapiFile) => void;
}

const DeleteFile = (props: IDeleteFile) => {
  const {
    loading = false,
    file,
    onFileDelete = (_: IStrapiFile) => {},
  } = props;
  const { t } = useTranslation('PROFILE');
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const onDeleteConfirm = () => {
    onFileDelete(file);
    setOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="ml-4 inline-flex items-center p-0.5 border border-transparent rounded-full text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="leading-6 text-md font-bold text-gray-800"
                  >
                    {t('CONFIRM_DELETE_FILE')}
                  </Dialog.Title>

                  <div className="mt-6 py-4 px-6 bg-gray-50">
                    <div className="flex flex-row items-center mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7 text-gray-400 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="mb-0 text-base font-normal text-gray-700">
                        {file.name}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <GreyButton
                    text={t('COMMON:NO')}
                    onClick={() => setOpen(false)}
                  />
                  <PrimaryButton
                    text={t('COMMON:YES')}
                    loading={loading}
                    onClick={onDeleteConfirm}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteFile;
