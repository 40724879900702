import { TermsTxt } from '../../../components/Policy';

const TermsConditions = () => {
  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <TermsTxt />
      </div>
    </div>
  );
};

export default TermsConditions;
