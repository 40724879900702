import { useTranslation } from 'react-i18next';
import { ITecdocProduct } from '../../../lib/fetch/products';
import { InfoDetailBox } from '../Components';

interface ITechnicalSpecsProps {
  product: { id: number; tecdoc?: ITecdocProduct };
}

const TechnicalSpecs = (props: ITechnicalSpecsProps) => {
  const { product } = props;
  const { t } = useTranslation('PRODUCTS');

  const tecdocDetails =
    product.tecdoc?.articleCriteria.map((crtieria) => ({
      title: crtieria.criteriaDescription,
      value: crtieria.formattedValue,
    })) || [];

  return !!tecdocDetails.length ? (
    <div className="flex flex-col gap-4 md:flex-row">
      <div className="flex-1">
        <h3 className="mb-4 text-lg font-semibold text-gray-600">
          {t('DETAIL_PRODUCT_INFO')}
        </h3>
        <div className="text-sm">
          <InfoDetailBox details={tecdocDetails} />
        </div>
      </div>
      {/* <div className="flex-1">
        <h3 className="text-md mb-4 font-semibold text-gray-600">
          {t('DOCTOC_DETAIL')}
        </h3>
        <div className="text-xs">
          <InfoDetailBox />
        </div>
      </div> */}
    </div>
  ) : (
    <>{t('DETAILS_CURRENTLY_NOT_AVAILABLE')}</>
  );
};

export default TechnicalSpecs;
