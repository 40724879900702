import { AuthAction, AuthActionType } from '../../providers/AuthProvider';
import { AlertAction } from '../../providers/AlertProvider';
import { ShoppingCartOverlayAction } from '../../providers/ShoppingCartOverlayProvider';
import { me } from '../fetch/auth';
import { IProduct, IProductAttributes } from '../fetch/products';
import {
  create as _createCartItem,
  update as _updateCartItem,
  _delete as _deleteCartItem,
  ICartItemCreate,
  ICartItemUpdate,
  ICartItem,
} from '../fetch/cartItems';
import {
  create as _createCart,
  update as _updateCart,
  ICartCreate,
  ICartUpdate,
} from '../fetch/carts';
import { IUser } from '../interfaces';
import { ITotals } from '../../components/Subtotal';

const createCartItem = async (
  token: string,
  product: IProduct,
  quantity: number
) => {
  const price = product.attributes.price || 0;
  // TODO: use real customPrice. Set it as list price ATM.
  const customPrice = price;
  const discount = price - customPrice;
  // TODO: use real taxes.
  const taxes = 0;
  const cartItem: ICartItemCreate = {
    quantity,
    price,
    discount,
    taxes,
    shipping: 0, // Set by the backend, the value set here is ignored. Left for compatibility.
    product: product.id,
  };
  return _createCartItem(token, cartItem);
};

const updateCartItem = async (
  token: string,
  cartItem: ICartItem,
  product: IProduct,
  quantity: number
) => {
  const price = product.attributes.price || 0;
  // TODO: use real customPrice. Set it as list price ATM.
  const customPrice = price;
  const discount = price - customPrice;
  // TODO: use real taxes.
  const taxes = 0;
  // TODO: use real shipping.
  const shipping = 0;
  const cartItemUpdate: ICartItemUpdate = {
    id: cartItem.id,
    quantity,
    price,
    discount,
    taxes,
    shipping,
    product: product.id,
  };
  return _updateCartItem(token, cartItemUpdate);
};

export const deleteCartItem = async (
  token: string,
  user: IUser,
  cartItem: ICartItem
) => {
  const { error: deleteCartItemError } = await _deleteCartItem(token, cartItem);
  if (deleteCartItemError)
    return {
      error: deleteCartItemError,
    };
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  const { shipping_address } = cart!;
  const shippingAddressId = shipping_address && shipping_address.id;
  cartItems = cartItems || [];
  const updatedCartItems = cartItems
    .map(({ id }) => id)
    .filter((id) => id !== cartItem.id);
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: updatedCartItems,
    shipping_address: updatedCartItems.length ? shippingAddressId : null,
  };
  return _updateCart(token, cartUpdate);
};

export const flushCart = async (token: string, user: IUser) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  for (let cartItem of cartItems) {
    await _deleteCartItem(token, cartItem);
  }
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: [],
    shipping_address: null,
  };
  return _updateCart(token, cartUpdate);
};

const createCart = async (
  token: string,
  user: IUser,
  product: IProduct,
  quantity: number
) => {
  const createCartItemRes = await createCartItem(token, product, quantity);
  if (createCartItemRes.error)
    return {
      error: createCartItemRes.error,
    };
  if (!createCartItemRes.data)
    return {
      error: new Error('No data returned by createCartItem'),
    };

  const {
    data: { id: cartItemId },
  } = createCartItemRes.data;
  const { id: userId } = user;
  const cart: ICartCreate = {
    users_permissions_user: userId,
    cart_items: [cartItemId],
  };
  return _createCart(token, cart);
};

export const updateCartShippingAddress = async (
  token: string,
  user: IUser,
  shippingAddressId: number
) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: cartItems.map(({ id }) => id),
    shipping_address: shippingAddressId,
  };
  return _updateCart(token, cartUpdate);
};

export const updateCart = async (
  token: string,
  user: IUser,
  product: IProduct,
  quantity: number,
  replaceQuantity = false
) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  const { shipping_address } = cart!;
  const shippingAddressId = shipping_address && shipping_address.id;
  const { id: addedProductId } = product;
  const itemAlreadyInCart = cartItems.find((cartItem: ICartItem) => {
    const { product: cartItemProduct } = cartItem;
    const { id: cartItemProductId } = cartItemProduct || {};
    return cartItemProductId === addedProductId;
  });
  if (itemAlreadyInCart) {
    const { quantity: alreadyInCartQuantity } = itemAlreadyInCart;
    return updateCartItem(
      token,
      itemAlreadyInCart,
      product,
      replaceQuantity ? quantity : quantity + alreadyInCartQuantity
    );
  }

  const createCartItemRes = await createCartItem(token, product, quantity);
  if (createCartItemRes.error)
    return {
      error: createCartItemRes.error,
    };
  if (!createCartItemRes.data)
    return {
      error: new Error('No data returned by createCartItem'),
    };

  const {
    data: { id: createdCartItemId },
  } = createCartItemRes.data;
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: [...cartItems.map(({ id }) => id), createdCartItemId],
    shipping_address: shippingAddressId,
  };
  return _updateCart(token, cartUpdate);
};

export const addToCart = async (
  token: string,
  user: IUser,
  product: IProduct,
  quantity: number
) => {
  const { cart } = user;
  return cart
    ? updateCart(token, user, product, quantity)
    : createCart(token, user, product, quantity);
};

export interface IAddToCartWithFeedback {
  product: IProduct;
  quantity: number;
  setAdding: (adding: boolean) => void;
  token: string;
  user: IUser;
  lang: string;
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  dispatchCartOverlayChange: (action: ShoppingCartOverlayAction) => void;
  t: (key: string) => string;
}

export const addToCartWithFeedback = async ({
  dispatchAlertChange,
  dispatchAuthChange,
  dispatchCartOverlayChange,
  product,
  quantity,
  setAdding,
  t,
  token,
  user,
  lang,
}: IAddToCartWithFeedback) => {
  if (!quantity) return;

  try {
    setAdding(true);
    const { error: addError } = await addToCart(
      token!,
      user!,
      product,
      +quantity
    );
    if (addError) {
      setAdding(false);
      return dispatchAlertChange({
        open: true,
        message: t('SHOPPING_CART:ADD_ERROR'),
      });
    }
    const { error: meError, data: updatedUser } = await me(token!, lang);
    if (meError) {
      setAdding(false);
      dispatchAlertChange({
        open: true,
        message: t('LOGIN:ERROR'),
      });
      return dispatchAuthChange({
        type: AuthActionType.Logout,
      });
    }
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    setAdding(false);
    // Do not open cart immediately, to prevent a blocked scrolling bug.
    setTimeout(() => dispatchCartOverlayChange({ open: true }), 1000);
  } catch (e) {
    setAdding(false);
    dispatchAlertChange({
      open: true,
      message: t('SHOPPING_CART:ADD_ERROR'),
    });
  }
};

interface IUpdateUserCart {
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  setLoading: (adding: boolean) => void;
  t: (key: string) => string;
  token: string;
  lang: string;
}

const updateUserCart = async ({
  dispatchAlertChange,
  dispatchAuthChange,
  setLoading,
  t,
  token,
  lang,
}: IUpdateUserCart) => {
  const { error: meError, data: updatedUser } = await me(token!, lang);
  if (meError) {
    setLoading(false);
    dispatchAlertChange({
      open: true,
      message: t('LOGIN:ERROR'),
    });
    return dispatchAuthChange({
      type: AuthActionType.Logout,
    });
  }
  dispatchAuthChange({
    type: AuthActionType.SetUser,
    user: updatedUser,
  });
  setLoading(false);
};

export interface IDeleteCartItemWithFeedback {
  cartItem: ICartItem;
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  setLoading: (adding: boolean) => void;
  t: (key: string) => string;
  token: string;
  user: IUser;
  lang: string;
}

export const deleteCartItemWithFeedback = async ({
  cartItem,
  dispatchAlertChange,
  dispatchAuthChange,
  setLoading,
  t,
  token,
  user,
  lang,
}: IDeleteCartItemWithFeedback) => {
  setLoading(true);
  const { error: deleteCartItemError } = await deleteCartItem(
    token!,
    user!,
    cartItem
  );
  if (deleteCartItemError) {
    setLoading(false);
    return dispatchAlertChange({
      open: true,
      message: t('SHOPPING_CART:UPDATE_ERROR'),
    });
  }
  setLoading(false);
  return updateUserCart({
    dispatchAlertChange,
    dispatchAuthChange,
    setLoading,
    t,
    token,
    lang,
  });
};

export interface IUpdateCartWithFeedback extends IDeleteCartItemWithFeedback {
  newQuantity: number;
}

export const updateCartWithFeedback = async ({
  cartItem,
  dispatchAlertChange,
  dispatchAuthChange,
  newQuantity,
  setLoading,
  t,
  token,
  user,
  lang,
}: IUpdateCartWithFeedback) => {
  setLoading(true);
  const product: IProduct = {
    id: cartItem.product.id,
    attributes: {
      ...cartItem.product,
    } as unknown as IProductAttributes,
  };
  const { error: updateCartError } = await updateCart(
    token!,
    user!,
    product,
    newQuantity,
    true
  );
  if (updateCartError) {
    setLoading(false);
    return dispatchAlertChange({
      open: true,
      message: t('SHOPPING_CART:UPDATE_ERROR'),
    });
  }
  return updateUserCart({
    dispatchAlertChange,
    dispatchAuthChange,
    setLoading,
    t,
    token,
    lang,
  });
};

export const calculateCartTotals = (
  cartItems: ICartItem[],
  cartShipping?: number
): ITotals => {
  const subTotal = cartItems.reduce((acc, cartItem) => {
    const { quantity, product } = cartItem;
    const { price } = product || {};
    return acc + (quantity * (price || 0) || 0) || 0;
  }, 0);
  const totalDiscount = cartItems.reduce((acc, cartItem) => {
    const { discount, quantity } = cartItem;
    return acc + (quantity * (discount || 0) || 0) || 0;
  }, 0);
  const totalTaxes = cartItems.reduce((acc, cartItem) => {
    const { quantity, taxes } = cartItem;
    return acc + (quantity * (taxes || 0) || 0) || 0;
  }, 0);
  const totalShipping =
    typeof cartShipping === 'number'
      ? cartShipping
      : cartItems.reduce((acc, cartItem) => {
          const { quantity, shipping } = cartItem;
          return acc + (quantity * (shipping || 0) || 0) || 0;
        }, 0);
  const subTotalNet = cartItems.reduce((acc, cartItem) => {
    const { quantity, price } = cartItem;
    return acc + (quantity * (price || 0) || 0) || 0;
  }, 0);
  const total = subTotalNet + totalTaxes + totalShipping;

  return {
    subTotal,
    totalDiscount,
    totalTaxes,
    totalShipping,
    subTotalNet,
    total,
  };
};
