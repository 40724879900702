import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import errorLottie from '../../lotties/error.json';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

const NotFound = () => {
  const { t } = useTranslation('NOT_FOUND');
  return (
    <div className="min-h-screen flex bg-gray-50">
      <div className="flex justify-center items-center flex-col w-full p-12">
        <Lottie
          loop
          animationData={errorLottie}
          play
          speed={0.2}
          style={{ width: 200, height: 200 }}
        />

        <h1 className="text-red-700 font-bold text-4xl mb-8 text-center">
          {t('TITLE')}
        </h1>

        <p
          className="mb-8 text-sm text-gray-600 text-center"
          dangerouslySetInnerHTML={{
            __html: t('DESCRIPTION'),
          }}
        />
        <Link to="/login">
          <PrimaryButton text={t('FORGOT:BACK_LOGIN')} />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
