import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useQueryParam, StringParam } from 'use-query-params';
import { classNames } from '../../lib/utils';

export const SORTING_OPTIONS = Object.freeze([
  { value: 'price:asc', name: 'SORT_PRICE_ASC' },
  { value: 'price:desc', name: 'SORT_PRICE_DESC' },
  // { value: 'sold:desc', name: 'SORT_MOST_SOLD' },
]);

const HorizontalFilter = () => {
  const [selected, setSelected] = useState(SORTING_OPTIONS[0]);
  const { t } = useTranslation('PRODUCTS');
  const [sort, setSort] = useQueryParam('sort', StringParam);

  useEffect(() => {
    if (!sort) return setSort(SORTING_OPTIONS[0].value);

    const sortingOption = SORTING_OPTIONS.find(
      (option) => option.value === sort
    );
    if (!sortingOption) setSort(SORTING_OPTIONS[0].value);
    else setSelected(sortingOption);
  }, [sort]);

  return (
    <div className="flex justify-end my-4">
      <div className="flex items-center">
        <Listbox
          value={selected}
          onChange={(selected: typeof SORTING_OPTIONS[number]) => {
            setSort(selected.value);
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-light text-gray-400 mr-2">
                {t('SORT_BY')}
              </Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button
                  className="relative w-full bg-gray-100 rounded-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
                  style={{ minWidth: '200px' }}
                >
                  <span className="block truncate">{t(selected.name)}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {SORTING_OPTIONS.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-red-600' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-8 pr-4'
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {t(option.name)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-red-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export default HorizontalFilter;
