import { IProduct } from '../fetch/products';

const MAX_PRODUCT_QUANTITY = 99;

const quantityInputProps = (product: IProduct) => {
  const { attributes } = product;
  const { quantity, dismissed } = attributes;
  const isUnavailable = !quantity && dismissed;
  return {
    max: isUnavailable ? 0 : quantity || MAX_PRODUCT_QUANTITY,
    min: isUnavailable ? 0 : 1,
    defaultValue: isUnavailable ? 0 : 1,
    disabled: isUnavailable,
    step: 1,
  };
};

export default quantityInputProps;
