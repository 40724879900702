import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../lib/interfaces';
import { COUNTRIES } from '../../../../../lib/utils';

const Address = ({ address }: { address: IAddress }) => {
  const {
    name,
    phone,
    addressFirstLine,
    addressSecondLine,
    postalCode,
    municipality,
    province,
    country,
  } = address;
  const { t } = useTranslation('PROFILE');

  return (
    <p className="text-sm text-gray-600">
      <span className="font-semibold">{name}</span>
      <br />
      {addressSecondLine}
      <br />
      {addressFirstLine}
      <br />
      {municipality}, {province}, {postalCode}
      <br />
      {t(COUNTRIES.find(({ value }) => value === country)?.name || '-')}
      <br />
      <br />
      {phone}
    </p>
  );
};

export default Address;
