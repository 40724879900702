import { Routes, Route } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './components/Auth';
import ActivationRequest from './pages/ActivationRequest';
import EditProfile from './pages/EditProfile';
import SearchResults from './pages/SearchResults';
import ProductDetail from './pages/ProductDetail';
import ShoppingCart from './pages/ShoppingCart';
import CheckoutShipping from './pages/CheckoutShipping';
import CheckoutPayment from './pages/CheckoutPayment';
import CheckoutResult from './pages/CheckoutResult';
import MyOrders from './pages/MyOrders';
import Faq from './pages/Faq';
import Forgot from './pages/Forgot';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import Reset from './pages/Reset';
import Shipping from './pages/Shipping';
import TermsConditions from './pages/TermsConditions';
import ThankYouRegistration from './pages/ThankYouRegistration';
import './App.css';

export const SEARCH_RESULTS_PATHNAME = '/search-results';
export const PRODUCT_DETAIL_PATHNAME = '/product-detail';
export const CHECKOUT_PAYMENT_PATHNAME = '/checkout-payment';
export const MY_ORDERS_PATHNAME = '/my-orders';

const App = () => {
  return (
    <Routes>
      {/* Public routes, with redirect if user is authenticated */}
      <Route index element={<PublicRoute element={<Login />} />} />
      <Route path="/login" element={<PublicRoute element={<Login />} />} />
      <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />
      <Route path="/reset" element={<PublicRoute element={<Reset />} />} />
      <Route
        path="/activation-request"
        element={<PublicRoute element={<ActivationRequest />} />}
      />
      <Route
        path="/thank-you-registration"
        element={<PublicRoute element={<ThankYouRegistration />} />}
      />
      {/* Private routes, with redirect if user is NOT authenticated */}
      <Route
        path="/profile/edit"
        element={<PrivateRoute element={<EditProfile />} />}
      />
      <Route
        path="/profile/*"
        element={<PrivateRoute element={<Profile />} />}
      />
      <Route
        path={SEARCH_RESULTS_PATHNAME}
        element={<PrivateRoute element={<SearchResults />} />}
      />
      <Route
        path={`${PRODUCT_DETAIL_PATHNAME}/:id`}
        element={<PrivateRoute element={<ProductDetail />} />}
      />
      <Route
        path="/shopping-cart"
        element={<PrivateRoute element={<ShoppingCart />} />}
      />
      <Route
        path="/checkout-shipping"
        element={<PrivateRoute element={<CheckoutShipping />} />}
      />
      <Route
        path={MY_ORDERS_PATHNAME}
        element={<PrivateRoute element={<MyOrders />} />}
      />
      <Route
        path={CHECKOUT_PAYMENT_PATHNAME}
        element={<PrivateRoute element={<CheckoutPayment />} />}
      />
      <Route
        path={'/checkout-result'}
        element={<PrivateRoute element={<CheckoutResult />} />}
      />
      {/* Public routes, without redirect */}
      <Route path="/faq" element={<Faq />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/shipping" element={<Shipping />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
