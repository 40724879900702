import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider';
import ProductDetailPresentational from './ProductDetailPresentational';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import {
  getCrossReferences,
  getOne as getProduct,
  IProduct,
} from '../../lib/fetch/products';
import { cartUtils, IAddToCartWithFeedback } from '../../lib/utils';
import i18n from '../../i18n';

const ProductDetail = () => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const navigation = useNavigate();
  const { id } = useParams();
  const [, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IProduct>();
  const [crossReferences, setCrossReferences] = useState<IProduct[]>([]);
  const [loadingCrossReferences, setLoadingCrossReferences] = useState(false);

  const { t } = useTranslation('PRODUCTS');
  const lang = i18n.languages[0];

  const onAddToCart = async (product: IProduct, quantity: number) => {
    return cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      product,
      quantity,
      setAdding,
      t,
      token,
      user,
      lang,
    } as IAddToCartWithFeedback);
  };

  const fetchCrossReferences = async () => {
    setLoadingCrossReferences(true);
    const { error, data: responseData } = await getCrossReferences(
      token!,
      product!.id,
      lang
    );
    setLoadingCrossReferences(false);
    if (error || !responseData.data) {
      setCrossReferences([]);
    }
    setCrossReferences(responseData.data);
  };

  useEffect(() => {
    const productId = +id!;
    productId &&
      (async () => {
        setLoading(true);
        const {
          data: { data },
          error,
        } = await getProduct(token!, productId, lang);
        if (error || !data) {
          setLoading(false);
          navigation('/error', { replace: true });
        }
        setProduct(data);
        setLoading(false);
      })();
  }, [id]);

  useEffect(() => {
    if (!product) return;
    fetchCrossReferences();
  }, [product]);

  return (
    <ProductDetailPresentational
      adding={adding}
      onAddToCart={onAddToCart}
      loading={loading}
      product={product}
      crossReferences={crossReferences}
      loadingCrossReferences={loadingCrossReferences}
    />
  );
};

export default ProductDetail;
