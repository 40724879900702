import { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EditField from './EditField';
import ExtendedPriceField from './ExtendedPriceField';
import { getThumbnail } from '../index';
import { PRODUCT_DETAIL_PATHNAME } from '../../../App';
import { HIDDEN_MANUFACTURERS_NAMES } from '../../../lib/constants';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { useSettings } from '../../../providers/SettingsProvider';
import {
  getTecdocManufacturerById,
  getTecdocManufacturerByName,
} from '../../../lib/utils/manufacturers';
import DetailsDisclousre from '../DetailsDisclosure';

interface IShoppingCartTable {
  cartItems: ICartItem[];
  onItemQuantityChange: (item: ICartItem, quantity: number) => void;
  onItemRemove: (item: ICartItem) => void;
}

const ShoppingCartTable: FC<IShoppingCartTable> = (props) => {
  const {
    cartItems = [],
    onItemQuantityChange = () => {},
    onItemRemove = () => {},
  } = props;
  const { t } = useTranslation('PRODUCTS');
  const [{ tecdocManufacturers }] = useSettings();

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only">{t('DETAILS')}</span>
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {cartItems.map((cartItem, index) => (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <tr className="border-t">
                          <td className="py-2 pl-2">
                            <Disclosure.Button className="bg-white text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                              <ChevronRightIcon
                                className={`${
                                  open ? 'rotate-90 transform' : ''
                                } h-4 w-4`}
                              />
                            </Disclosure.Button>
                          </td>
                          <td className="whitespace-nowrap py-2 pl-2 pr-4">
                            <div className="flex items-center">
                              {getThumbnail(
                                cartItem.product.tecdoc?.images[0]
                                  ?.imageURL3200 || cartItem.product.thumbnail
                              )}
                              <Link
                                to={`${PRODUCT_DETAIL_PATHNAME}/${cartItem.product.id}`}
                                className="ml-3"
                              >
                                <div className="text-sm font-medium text-gray-900">
                                  {cartItem.product.code}
                                </div>
                                <div className="text-sm font-normal text-gray-400">
                                  {cartItem.product.tecdoc?.genericArticles[
                                    cartItem.product.tecdoc?.genericArticles
                                      .length - 1
                                  ]?.genericArticleDescription ||
                                    cartItem.product.name ||
                                    cartItem.product.family.name}
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-2">
                            {cartItem.product.tecdoc?.dataSupplierId &&
                            getTecdocManufacturerById(
                              cartItem.product.tecdoc?.dataSupplierId,
                              tecdocManufacturers
                            ) ? (
                              <img
                                className="h-auto w-20"
                                src={
                                  getTecdocManufacturerById(
                                    cartItem.product.tecdoc?.dataSupplierId,
                                    tecdocManufacturers
                                  )!.dataSupplierLogo.imageURL800
                                }
                                alt=""
                              />
                            ) : cartItem.product.manufacturer.name &&
                              getTecdocManufacturerByName(
                                cartItem.product.manufacturer.name,
                                tecdocManufacturers
                              ) ? (
                              <img
                                className="h-auto w-20"
                                src={
                                  getTecdocManufacturerByName(
                                    cartItem.product.manufacturer.name,
                                    tecdocManufacturers
                                  )!.dataSupplierLogo.imageURL800
                                }
                                alt=""
                              />
                            ) : cartItem.product.manufacturer?.name &&
                              !HIDDEN_MANUFACTURERS_NAMES.includes(
                                `${cartItem.product.manufacturer?.name}`.toUpperCase()
                              ) ? (
                              cartItem.product.manufacturer.name
                            ) : (
                              cartItem.product.manufacturer?.id || '-'
                            )}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold">
                            <ExtendedPriceField cartItem={cartItem} />
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-right text-sm font-medium">
                            <EditField
                              cartItem={cartItem}
                              onQuantityChange={onItemQuantityChange}
                              onRemove={onItemRemove}
                            />
                          </td>
                        </tr>
                        <tr className="border-0">
                          <td colSpan={5} className="border-0">
                            <DetailsDisclousre
                              product={cartItem.product}
                              showTechnicalSpecs={true}
                              showCrossReferences={false}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </Disclosure>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartTable;
