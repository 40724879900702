import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../../../lib/enums';
import { classNames } from '../../../lib/utils';
import { IWireTransferDataAttributes } from '../../../lib/fetch/wireTransfer';
import WireTransferInfo from '../../../components/WireTransferInfo';
import { ALLOWED_PAYMENT_METHODS } from '../../../lib/constants';

interface IRadioGroupPayment {
  wireTransferData: IWireTransferDataAttributes | undefined;
}

const RadioGroupPayment = (props: IRadioGroupPayment) => {
  const { wireTransferData } = props;
  const { register } = useFormContext();
  const { t } = useTranslation('SHOPPING_CART');

  return (
    <div>
      <fieldset className="mt-4">
        <div className="space-y-6">
          {ALLOWED_PAYMENT_METHODS.includes(PaymentMethod.Card) && (
            <div key="payment1" className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  {...register('paymentMethod')}
                  type="radio"
                  name="paymentMethod"
                  id="payment1"
                  value={PaymentMethod.Card}
                  className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="payment1" className="font-medium text-gray-800">
                  {t('CREDIT_CARD')}
                </label>
                <img
                  className="mt-2 h-auto w-48"
                  alt="Credit Card"
                  src="../images/credit-cards.png"
                />
              </div>
            </div>
          )}

          {ALLOWED_PAYMENT_METHODS.includes(PaymentMethod.Paypal) && (
            <div key="payment2" className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  {...register('paymentMethod')}
                  type="radio"
                  name="paymentMethod"
                  id="payment2"
                  value={PaymentMethod.Paypal}
                  className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="payment2" className="font-medium text-gray-800">
                  {t('PAYPAL')}
                </label>
                <img
                  className="mt-2 h-auto w-20"
                  alt="Paypal"
                  src="../images/Paypal-Logo.png"
                />
              </div>
            </div>
          )}

          {ALLOWED_PAYMENT_METHODS.includes(PaymentMethod.WireTransfer) && (
            <div
              key="payment3"
              className={classNames(
                'relative flex items-start',
                !wireTransferData && 'hidden'
              )}
            >
              <div className="flex h-5 items-center">
                <input
                  {...register('paymentMethod')}
                  disabled={!wireTransferData}
                  type="radio"
                  name="paymentMethod"
                  id="payment3"
                  value={PaymentMethod.WireTransfer}
                  className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="payment3" className="font-medium text-gray-800">
                  {t('WIRE_TRANSFER')}
                </label>
                <WireTransferInfo wireTransferData={wireTransferData} />
              </div>
            </div>
          )}

          {/* TODO: Allow cash payment */}
          {ALLOWED_PAYMENT_METHODS.includes(PaymentMethod.Cash) && (
            <div key="payment4" className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  {...register('paymentMethod')}
                  type="radio"
                  name="paymentMethod"
                  id="payment4"
                  aria-describedby="description`"
                  value={PaymentMethod.Cash}
                  className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="payment4"
                  className="font-medium text-gray-800"
                  dangerouslySetInnerHTML={{ __html: t('CASH_DELIVERY') }}
                />
              </div>
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioGroupPayment;
