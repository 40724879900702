import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MinusSmIcon, PlusIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { SingleWordButtonPrimary } from '../../Buttons';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { PRODUCT_DETAIL_PATHNAME } from '../../../App';
import {
  DEFAULT_IMAGE_PLACEHOLDER,
  HIDDEN_MANUFACTURERS_NAMES,
} from '../../../lib/constants';
import { isBackgroundCompatible } from '../../ProductsTable';
import { currency, quantityInputProps } from '../../../lib/utils';
import { IProduct } from '../../../lib/fetch/products';
import {
  getTecdocManufacturerById,
  getTecdocManufacturerByName,
} from '../../../lib/utils/manufacturers';
import { useSettings } from '../../../providers/SettingsProvider';

const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-28 w-24 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER})`,
          minWidth: '6rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-28 w-24 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '6rem',
        }}
      />
    );
  return (
    <img
      className="h-28 w-24"
      style={{ minWidth: '6rem' }}
      src={encodeURI(thumbnail)}
      alt=""
    />
  );
};

interface ISingleProduct {
  cartItem: ICartItem;
  onQuantityChange: (cartItem: ICartItem, quantity: number) => void;
  onRemove: (cartItem: ICartItem) => void;
}

const SingleProduct: FC<ISingleProduct> = (props) => {
  const {
    cartItem,
    onQuantityChange: _onQuantityChange = () => {},
    onRemove = () => {},
  } = props;
  const [{ tecdocManufacturers }] = useSettings();
  const { discount, price, product } = cartItem;
  const cartItemQuantity = +cartItem.quantity || 0;
  const [quantity, setQuantity] = useState(cartItemQuantity);
  const { manufacturer } = product;
  const {
    max: maxQuantity,
    min: minQuantity,
    disabled: isUnavailable,
  } = quantityInputProps({ attributes: product } as unknown as IProduct);
  const { t } = useTranslation('PRODUCTS');
  const { handleSubmit, register, setValue, watch } = useForm({
    defaultValues: { quantity },
  });
  const onQuantityChange = useDebouncedCallback(_onQuantityChange, 1000);
  const onSubmit = handleSubmit(({ quantity: newQuantity }) => {
    setQuantity(+newQuantity || quantity);
  });

  useEffect(() => {
    setValue('quantity', quantity);
    quantity !== cartItemQuantity && onQuantityChange(cartItem, quantity);
  }, [quantity]);

  useEffect(
    () =>
      watch((formData, { name, type }) => {
        name === 'quantity' && type === 'change' && onSubmit();
      }).unsubscribe,
    [watch]
  );

  return (
    <div className="flex space-x-3 pt-6 text-gray-400">
      {getThumbnail(
        product.tecdoc?.images[0]?.imageURL3200 || product.thumbnail
      )}
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-col justify-between">
          <div className="space-y-1">
            <h3 className="text-base font-bold text-gray-700">
              <Link to={`${PRODUCT_DETAIL_PATHNAME}/${product.id}`}>
                {product.code}
              </Link>
            </h3>
            <p className="text-xs text-gray-400">
              {product.tecdoc?.genericArticles[
                product.tecdoc?.genericArticles.length - 1
              ].genericArticleDescription ||
                product.name ||
                product.family.name}
            </p>
            {product.tecdoc?.dataSupplierId &&
            getTecdocManufacturerById(
              product.tecdoc?.dataSupplierId,
              tecdocManufacturers
            ) ? (
              <img
                className="h-auto w-14"
                src={
                  getTecdocManufacturerById(
                    product.tecdoc?.dataSupplierId,
                    tecdocManufacturers
                  )?.dataSupplierLogo.imageURL800
                }
                alt=""
              />
            ) : manufacturer.name &&
              getTecdocManufacturerByName(
                manufacturer.name,
                tecdocManufacturers
              ) ? (
              <img
                className="h-auto w-14"
                src={
                  getTecdocManufacturerByName(
                    manufacturer.name,
                    tecdocManufacturers
                  )?.dataSupplierLogo.imageURL800
                }
                alt=""
              />
            ) : manufacturer.name ? (
              HIDDEN_MANUFACTURERS_NAMES.includes(manufacturer.name) ? (
                manufacturer.id
              ) : (
                manufacturer.name
              )
            ) : (
              manufacturer.id
            )}
          </div>
          <form
            className="flex flex-row items-center gap-2"
            onSubmit={onSubmit}
          >
            <p className="text-xs">{t('QTY')}:</p>
            <button
              type="button"
              disabled={quantity < 2}
              onClick={() => setQuantity(quantity - 1)}
            >
              <MinusSmIcon className="h-3 w-3" />
            </button>
            <input
              type="number"
              className="block w-full w-14 rounded-md border-transparent bg-white text-xs text-gray-900 shadow-sm focus:border-red-500 focus:ring-red-500"
              max={maxQuantity}
              min={minQuantity}
              step="1"
              disabled={isUnavailable}
              {...register('quantity', {
                max: maxQuantity,
                min: minQuantity,
                disabled: isUnavailable,
                required: true,
              })}
            />
            <button
              type="button"
              disabled={isUnavailable || quantity >= maxQuantity}
              onClick={() => setQuantity(quantity + 1)}
            >
              <PlusIcon className="h-3 w-3" />
            </button>
          </form>
        </div>
        <div className="flex flex-col items-end justify-between text-right">
          <div className="space-y-1">
            <p className="flex-none text-xs">
              {t('LIST')}:
              <span className="ml-3 font-bold">
                {product.price
                  ? `${currency(product.price)} (${quantity}x)`
                  : '-'}
              </span>
            </p>
            {discount ? (
              <p className="flex-none text-xs">
                {t('DISCOUNT')}:
                <span className="ml-3 font-bold">
                  {`${currency(discount)} (${quantity}x)`}
                </span>
              </p>
            ) : null}
            <p className="flex-none text-xs">
              {t('NET')}:{' '}
              <span className="ml-3 font-bold">
                {price ? `${currency(price)} (${quantity}x)` : '-'}
              </span>
            </p>
            <p className="flex-none text-xs">
              {t('FINAL')}:{' '}
              <span className="ml-3 text-base font-bold text-gray-900">
                {price ? currency(price * quantity) : '-'}
              </span>
            </p>
          </div>
          <SingleWordButtonPrimary
            onClick={() => onRemove(cartItem)}
            text={t('COMMON:DELETE')}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
