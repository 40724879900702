import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon, ShoppingCartIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { WhereToSearch } from '../../../lib/enums';
import { ISearchParams } from './index';

interface ISearchHeaderPresentational {
  onSearch: (searchParams: ISearchParams) => void;
  search: string;
  where: string;
}

const SearchHeaderPresentational: FC<ISearchHeaderPresentational> = (props) => {
  const { onSearch = () => {}, search, where } = props;
  const { t } = useTranslation('MENU');
  const {
    handleSubmit,
    register,
    setValue: setFormValue,
  } = useForm({
    defaultValues: {
      addToCart: false,
      search,
      where,
    },
  });

  return (
    <form className="flex items-center" onSubmit={handleSubmit(onSearch)}>
      <div className="relative mr-2 rounded-md" style={{ minWidth: '400px' }}>
        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="hidden items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-0 text-gray-500 sm:text-sm md:inline-flex">
            <select
              id="where"
              autoComplete="off"
              className="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-xs text-gray-500 outline-none focus:outline-none"
              {...register('where')}
            >
              <option value="">{t('EVERYWHERE')}</option>
              <option value={WhereToSearch.Code}>{t('CODE')}</option>
              <option value={WhereToSearch.Name}>{t('NAME')}</option>
            </select>
          </span>
          <input
            type="text"
            id="search"
            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 text-sm focus:border-gray-500 focus:ring-gray-500"
            placeholder={t('COMMON:SEARCH')}
            {...register('search')}
          />
        </div>
      </div>
      <div className="mr-2">
        <button
          type="submit"
          className="flex items-center justify-center rounded-full border border-transparent bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 lg:rounded-md lg:px-4"
          onClick={(e) => {
            setFormValue('addToCart', false);
          }}
        >
          <SearchIcon className="-ml-0.5 h-4 w-4 lg:mr-2" aria-hidden="true" />
          <span className="hidden lg:inline-block">{t('SEARCH')}</span>
        </button>
      </div>
      <button
        type="submit"
        className="flex items-center justify-center rounded-full border border-transparent bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 lg:rounded-md lg:px-4"
        onClick={() => {
          setFormValue('addToCart', true);
        }}
      >
        <ShoppingCartIcon
          className="-ml-0.5 h-4 w-4 lg:mr-2"
          aria-hidden="true"
        />
        <span className="hidden lg:inline-block">{t('ADD_TO_CART')}</span>
      </button>
    </form>
  );
};

export default SearchHeaderPresentational;
