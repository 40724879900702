import { IStrapiFile } from '../../../../lib/interfaces';
import DeleteFile from '../GeneralData/DeleteFile';

interface IPersonalFilePrev {
  loading: boolean;
  file: IStrapiFile;
  onFileDelete: (_: IStrapiFile) => void;
}

const PersonalFilePrev = (props: IPersonalFilePrev) => {
  const { file, loading, onFileDelete } = props;
  const { name, url } = file;

  return (
    <div className="flex flex-row items-center mb-2">
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="flex flex-row items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7 text-gray-400 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="mb-0 text-base font-normal text-gray-700">{name}</p>
      </a>
      <DeleteFile loading={loading} file={file} onFileDelete={onFileDelete} />
    </div>
  );
};

export default PersonalFilePrev;
