import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../lib/utils';

const PROFILE_ROUTES = Object.freeze([
  { name: 'MY_PERSONAL_DATA', to: '/profile', end: true },
  { name: 'MY_ADDRESSES', to: '/profile/addresses' },
  { name: 'RETURN_INFO', to: '/profile/return-info' },
  { name: 'ALL_DOCUMENTS', to: '/profile/documents' },
  { name: 'PRIVACY:TITLE', to: '/profile/privacy-policy' },
  { name: 'COOKIE:TITLE', to: '/profile/cookie-policy' },
  // { name: 'TERMS:TITLE', to: '/profile/terms-conditions' },
]);

export default function VerticalNavigationProfile() {
  const { t } = useTranslation('PROFILE');
  return (
    <nav className="flex flex-wrap space-y-1 lg:inline" aria-label="Sidebar">
      {PROFILE_ROUTES.map(({ name, to, end = false }, index) => (
        <NavLink
          key={index}
          to={to}
          end={end}
          className={({ isActive }) =>
            classNames(
              isActive
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
              'flex items-center rounded-md px-3 py-2 text-sm font-medium'
            )
          }
        >
          <span className="truncate">{t(name)}</span>
        </NavLink>
      ))}
    </nav>
  );
}
