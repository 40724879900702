import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAlert } from '../../providers/AlertProvider';
import { BackButton, PrimaryButtonFull } from '../../components/Buttons';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Subtotal from '../../components/Subtotal';
import NewAddressTxt from './Components/NewAddressTxt';
import RadioGroupAddressBox from './Components/RadioGroupAddressBox';
import ShippingStepTitle from './Components/ShippingStepTitle';
import CheckoutProductsTable from '../../components/ProductsTable/CheckoutProductsTable';
import { ICart } from '../../lib/fetch/carts';
import { IAddress } from '../../lib/interfaces';
import { AddressType } from './index';

interface ICheckoutShippingPresentational {
  cart?: ICart;
  addresses?: IAddress[];
  addressLoading: boolean;
  deliveryAddress: IAddress | undefined;
  billingAddress: IAddress | undefined;
  onAddressCreate: (address: IAddress, type: AddressType) => void;
  onAddressChange: (address: IAddress, type: AddressType) => void;
  onCheckoutProceed: () => void;
}

const CheckoutShippingPresentational: FC<ICheckoutShippingPresentational> = (
  props
) => {
  const {
    cart,
    addresses = [],
    addressLoading = false,
    deliveryAddress,
    billingAddress,
    onAddressCreate = (_: IAddress) => {},
    onAddressChange = (_: IAddress, _type: AddressType) => {},
    onCheckoutProceed = () => {},
  } = props;
  let { cart_items: cartItems, shipping: cartShipping } = cart || {};
  cartItems = cartItems || [];
  cartShipping = cartShipping || 0;
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SHOPPING_CART');

  return (
    <div className="min-h-screen">
      <Header />

      <div className="flex flex-col gap-6 lg:flex-row">
        <div className="flex h-full min-w-0 flex-1 flex-col px-4 py-10 lg:pl-6 lg:pr-0">
          <div className="mb-6">
            <Link to="/shopping-cart">
              <BackButton text={t('COMMON:BACK')} to="/shopping-cart" />
            </Link>
          </div>

          <div className="mb-10">
            <ShippingStepTitle
              stepNumber="01"
              text={t('CHOOSE_SHIPPING_TITLE')}
            />
            <RadioGroupAddressBox
              type={AddressType.Delivery}
              loading={addressLoading}
              addresses={addresses}
              selectedAddress={deliveryAddress}
              onAddressChange={onAddressChange}
            />
            <NewAddressTxt
              loading={addressLoading}
              onAddressCreate={(address) =>
                onAddressCreate(address, AddressType.Delivery)
              }
            />
          </div>

          <div className="mb-10">
            <ShippingStepTitle
              stepNumber="02"
              text={t('CHOOSE_BILLING_TITLE')}
            />
            <RadioGroupAddressBox
              type={AddressType.Billing}
              addresses={addresses}
              selectedAddress={billingAddress}
              onAddressChange={onAddressChange}
            />
            <NewAddressTxt
              loading={addressLoading}
              onAddressCreate={(address) =>
                onAddressCreate(address, AddressType.Billing)
              }
            />
          </div>

          <div>
            <ShippingStepTitle stepNumber="03" text={t('REVIEW_TITLE')} />
            <CheckoutProductsTable cartItems={cartItems} />
          </div>
        </div>

        <aside className="mx-auto w-full overflow-y-auto bg-gray-50 py-10 lg:w-2/6">
          <div className="px-4">
            <h3 className="mb-4 text-lg font-bold text-gray-900">
              {t('ORDER_SUMMARY')}
            </h3>
          </div>
          <Subtotal
            cartItems={cartItems}
            shipping={cartShipping}
            shippingAddress={cart?.shipping_address}
          />

          <div className="mt-10 px-4 uppercase">
            <PrimaryButtonFull
              text={t('PROCEED_BTN')}
              disabled={!deliveryAddress || !billingAddress}
              loading={addressLoading}
              onClick={(e) => {
                if (cartItems?.length) return onCheckoutProceed();
                e.preventDefault();
                dispatchAlertChange({
                  open: true,
                  message: t('EMPTY_CART_ERROR'),
                });
              }}
            />
          </div>
        </aside>
      </div>

      <Footer />
    </div>
  );
};

export default CheckoutShippingPresentational;
