import { ReactElement, ForwardedRef, forwardRef } from 'react';

interface ICheckBoxPresentationalWithLabel {
  label: string;
  id: string;
  name: string;
  hasError: boolean;
  helperText: string;
}

interface ICheckBoxPresentationalWithTransLabel {
  translabel: ReactElement;
  id: string;
  name: string;
  hasError: boolean;
  helperText: string;
}

type TCheckBoxPresentational =
  | ICheckBoxPresentationalWithLabel
  | ICheckBoxPresentationalWithTransLabel;

const CheckBoxPresentational = forwardRef(
  (props: TCheckBoxPresentational, ref: ForwardedRef<HTMLInputElement>) => {
    const { id, name, hasError, helperText, ...rest } = props;
    let label: string = '';
    let translabel: ReactElement | null = null;

    if (props.hasOwnProperty('label')) {
      label = (props as ICheckBoxPresentationalWithLabel).label;
    } else if (props.hasOwnProperty('translabel')) {
      translabel = (props as ICheckBoxPresentationalWithTransLabel).translabel;
    }

    return (
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            ref={ref}
            id={id}
            name={name}
            type="checkbox"
            className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
            {...rest}
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor={id}
            className="font-medium text-gray-700"
            dangerouslySetInnerHTML={label ? { __html: label } : undefined}
          >
            {translabel}
          </label>
          {helperText && (
            <p
              className={`mt-2 text-sm text-${
                hasError ? 'red-600' : 'gray-500'
              }`}
              id={`${id}-${hasError ? 'error' : 'helper'}`}
            >
              {helperText}
            </p>
          )}
        </div>
      </div>
    );
  }
);

export default CheckBoxPresentational;
