import { classNames } from '../../lib/utils';

interface ILightPrimaryButton {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
}

const LightPrimaryButton = ({
  text,
  type = 'button',
  onClick = () => {},
  disabled = false,
}: ILightPrimaryButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200 uppercase',
        disabled ? 'cursor-not-allowed' : 'hover:bg-red-300 hover:text-red-700'
      )}
    >
      {text}
    </button>
  );
};

export default LightPrimaryButton;
