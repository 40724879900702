import { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  MadeOrderBadge,
  OutProductionBadge,
  PiecesBadge,
  UnavailableBadge,
} from '../StockBadge';
import AddCartField from './AddCartField';
// import PromotionField from './PromotionField';
import { PRODUCT_DETAIL_PATHNAME } from '../../App';
import { currency } from '../../lib/utils';
import { IProduct } from '../../lib/fetch/products';
import {
  DEFAULT_IMAGE_PLACEHOLDER,
  HIDDEN_MANUFACTURERS_NAMES,
} from '../../lib/constants';
import { ImageMediaType } from '../../lib/enums';
import { useSettings } from '../../providers/SettingsProvider';
import {
  getTecdocManufacturerById,
  getTecdocManufacturerByName,
} from '../../lib/utils/manufacturers';
import DetailsDisclousre from './DetailsDisclosure';

export { default as HorizontalFilter } from './HorizontalFilter';
export { default as Pagination } from './Pagination';
export { default as WarehouseTable } from './WarehouseTable';

interface IProductsTable {
  onAddToCart: (product: IProduct, quantity: number) => void;
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
  products: IProduct[];
}

export const isBackgroundCompatible = (thumbnail: string) => {
  const url = (thumbnail || '').split('?').shift();
  const ext = (url || '').split('.').pop();
  return (
    [ImageMediaType.JPG, ImageMediaType.JPEG, ImageMediaType.PNG] as string[]
  ).includes((ext || '').toUpperCase());
};
export const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER})`,
          minWidth: '3rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '3rem',
        }}
      />
    );
  return (
    <img
      className="h-12 w-12"
      style={{ minWidth: '3rem' }}
      src={encodeURI(thumbnail)}
      alt="thumbnail"
    />
  );
};

const ProductsTable: FC<IProductsTable> = (props) => {
  const {
    onAddToCart = () => {},
    onFetchCrossReferences,
    products = [],
  } = props;
  const { t } = useTranslation('PRODUCTS');
  const [{ tecdocManufacturers }] = useSettings();

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only" />
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {products.map((product) => (
                  <Disclosure key={product.id}>
                    {({ open }) => (
                      <>
                        <tr className="border-t">
                          <td className="py-2 pl-2">
                            <Disclosure.Button className="bg-white text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                              <ChevronRightIcon
                                className={`${
                                  open ? 'rotate-90 transform' : ''
                                } h-4 w-4`}
                              />
                            </Disclosure.Button>
                          </td>
                          <td className="whitespace-nowrap py-2 pl-2 pr-4">
                            <div className="flex items-center">
                              {getThumbnail(
                                product.tecdoc?.images[0]?.imageURL3200 ||
                                  product.attributes.thumbnail
                              )}
                              <Link
                                to={`${PRODUCT_DETAIL_PATHNAME}/${product.id}`}
                                className="ml-3"
                              >
                                <div className="text-sm font-medium text-gray-900">
                                  {product.attributes.code}
                                </div>
                                <div className="text-sm font-normal text-gray-500">
                                  {product.tecdoc?.genericArticles[
                                    product.tecdoc?.genericArticles.length - 1
                                  ]?.genericArticleDescription ||
                                    product.attributes.name ||
                                    product.attributes.family?.data.attributes
                                      .name}
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-2">
                            {product.tecdoc?.dataSupplierId &&
                            getTecdocManufacturerById(
                              product.tecdoc?.dataSupplierId,
                              tecdocManufacturers
                            ) ? (
                              <img
                                className="h-auto w-20"
                                src={
                                  getTecdocManufacturerById(
                                    product.tecdoc?.dataSupplierId,
                                    tecdocManufacturers
                                  )!.dataSupplierLogo.imageURL800
                                }
                                alt=""
                              />
                            ) : product.attributes.manufacturer?.data.attributes
                                .name &&
                              getTecdocManufacturerByName(
                                product.attributes.manufacturer?.data.attributes
                                  .name,
                                tecdocManufacturers
                              ) ? (
                              <img
                                className="h-auto w-20"
                                src={
                                  getTecdocManufacturerByName(
                                    product.attributes.manufacturer?.data
                                      .attributes.name,
                                    tecdocManufacturers
                                  )!.dataSupplierLogo.imageURL800
                                }
                                alt=""
                              />
                            ) : product.attributes.manufacturer?.data.attributes
                                .name &&
                              !HIDDEN_MANUFACTURERS_NAMES.includes(
                                `${product.attributes.manufacturer?.data.attributes.name}`.toUpperCase()
                              ) ? (
                              product.attributes.manufacturer.data.attributes
                                .name
                            ) : (
                              product.attributes.manufacturer?.data.id || '-'
                            )}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2">
                            <div className="text-sm text-yellow-700">
                              {/* TODO: handle delivery days */}
                              {/*{t('DELIVERY_TIME_DAYS', {*/}
                              {/*  days: 180,*/}
                              {/*})}*/}-
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
                            {!product.attributes.quantity &&
                              (product.attributes.dismissed ? (
                                <div>
                                  <UnavailableBadge />
                                  <OutProductionBadge />
                                </div>
                              ) : (
                                <MadeOrderBadge />
                              ))}
                            <PiecesBadge
                              quantity={product.attributes.quantity}
                            />
                            {/* TODO: handle warehouses */}
                            {/*<WarehouseModal />*/}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
                            {product.attributes.price
                              ? currency(product.attributes.price)
                              : '-'}
                            {/* TODO: handle promotions */}
                            {/*<PromotionField />*/}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-right text-sm font-medium">
                            <AddCartField
                              onAdd={(quantity) =>
                                onAddToCart(product, quantity)
                              }
                              product={product}
                            />
                          </td>
                        </tr>
                        <tr className="border-0">
                          <td colSpan={7} className="border-0">
                            <DetailsDisclousre
                              product={product}
                              onAddToCart={onAddToCart}
                              showTechnicalSpecs={true}
                              showCrossReferences={true}
                              onFetchCrossReferences={onFetchCrossReferences}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </Disclosure>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
