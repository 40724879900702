import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { currency } from '../../../lib/utils';

interface IExtendedPriceField {
  cartItem: ICartItem;
}

const ExtendedPriceField: FC<IExtendedPriceField> = (props) => {
  const { cartItem } = props;
  const { discount, price, product, quantity } = cartItem || {};
  const { t } = useTranslation('PRODUCTS');
  return (
    <>
      <div className="flex flex-row gap-3">
        <div className="flex flex-col text-gray-400">
          <span className="text-xs font-light ">{t('LIST')}:</span>
          <span className="text-sm">
            {product.price ? `${currency(product.price)} (${quantity}x)` : '-'}
          </span>
        </div>

        {discount ? (
          <div className="flex flex-col text-gray-400">
            <span className="text-xs font-light ">{t('DISCOUNT')}:</span>
            <span className="text-sm">
              {`${currency(discount)} (${quantity}x)`}
            </span>
          </div>
        ) : null}

        <div className="flex flex-col text-gray-400">
          <span className="text-xs font-light ">{t('NET')}:</span>
          <span className="text-sm">
            {price ? `${currency(price)} (${quantity}x)` : '-'}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="text-xs font-light text-gray-400">
            {t('FINAL')}:
          </span>
          <span className="text-sm text-gray-700">
            {price ? currency(price * quantity) : '-'}
          </span>
        </div>
      </div>
    </>
  );
};

export default ExtendedPriceField;
