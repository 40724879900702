import axios, { AxiosResponse } from 'axios';
import { ICartCreate, ICartCreateResult } from './create';

const { REACT_APP_CARTS_URL } = process.env;

export interface ICartUpdate extends ICartCreate {
  id: number;
}

export interface ICartUpdateResult extends ICartCreateResult {}

const update = async (token: string, cart: ICartUpdate) => {
  const response = await axios.put<{ data: ICartUpdateResult }>(
    `${REACT_APP_CARTS_URL!}/${cart.id}`,
    { data: cart },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
