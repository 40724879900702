import { FC } from 'react';
import { Trans } from 'react-i18next';

interface IPiecesBadge {
  quantity?: number;
}

const PiecesBadge: FC<IPiecesBadge> = (props) => {
  const { quantity = 0 } = props;

  return quantity ? (
    <div>
      <div className="inline-flex items-center">
        <div className="h-3 w-3 bg-green-500 mr-2 rounded-full" />
        <Trans i18nKey={'PRODUCTS:PIECES'} count={quantity}>
          {' '}
          <span className="text-lg font-medium text-green-500 mr-1">
            {{ quantity }}
          </span>{' '}
          <span className="text-sm font-medium text-green-500">pieces</span>
        </Trans>
      </div>
    </div>
  ) : null;
};

export default PiecesBadge;
