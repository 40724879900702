import { FC } from 'react';

const PersonalDataBox: FC<{ title: string }> = ({ children, title }) => (
  <div className="border border-gray-300 rounded-xl px-6 py-6 mb-6">
    <h3 className="text-2xl font-bold text-gray-800 mb-6">{title}</h3>
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {children}
    </div>
  </div>
);

export default PersonalDataBox;
