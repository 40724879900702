import { CheckIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../lib/interfaces';
import Address from './Address';
import DeleteAddress from './DeleteAddress';
import EditAddress from './EditAddress';

interface IAddressBox {
  loading: boolean;
  address: IAddress;
  onAddressDelete: (_: IAddress) => void;
  onAddressEdit: (_: IAddress) => void;
}

const AddressBox = (props: IAddressBox) => {
  const {
    loading = false,
    address,
    onAddressDelete = (_: IAddress) => {},
    onAddressEdit = (_: IAddress) => {},
  } = props;
  const { title, deliveryDefault, billingDefault } = address;
  const { t } = useTranslation('PROFILE');

  return (
    <>
      <div className="border border-gray-300 rounded-lg">
        <div className="border-b border-gray-300 p-3">
          <h3 className="text-md font-bold text-gray-800">{title}</h3>
        </div>
        <div className="px-3 py-4">
          <Address address={address} />

          {deliveryDefault && (
            <div className="flex items-center mt-6">
              <div className="h-6 w-6 rounded-xl text-white bg-red-600 flex items-center justify-center  mr-2">
                <CheckIcon className="h-4 w-4" />
              </div>
              <p className="text-sm font-semibold text-gray-600">
                {t('DEFAULT_SHIPPING')}
              </p>
            </div>
          )}

          {billingDefault && (
            <div className="flex items-center mt-6">
              <div className="h-6 w-6 rounded-xl text-white bg-red-600 flex items-center justify-center  mr-2">
                <CheckIcon className="h-4 w-4" />
              </div>
              <p className="text-sm font-semibold text-gray-600">
                {t('DEFAULT_BILLING')}
              </p>
            </div>
          )}

          <div className="mt-6 flex items-center justify-end">
            <EditAddress
              loading={loading}
              address={address}
              onAddressEdit={onAddressEdit}
            />

            <span className="h-8 w-px bg-gray-200 mx-2" aria-hidden="true" />

            <DeleteAddress
              loading={loading}
              address={address}
              onAddressDelete={onAddressDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressBox;
