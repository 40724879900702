import { IAddress } from '../../../../lib/interfaces';
import AddressBox from './AddressBox';
import NewAddressBox from './NewAddressBox';

interface IAddressesPresentational {
  loading: boolean;
  addresses: IAddress[];
  onAddressCreate: (_: IAddress) => void;
  onAddressDelete: (_: IAddress) => void;
  onAddressEdit: (_: IAddress) => void;
}

const AddressesPresentational = (props: IAddressesPresentational) => {
  const {
    loading = false,
    addresses = [],
    onAddressCreate = (_: IAddress) => {},
    onAddressDelete = (_: IAddress) => {},
    onAddressEdit = (_: IAddress) => {},
  } = props;

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          <NewAddressBox loading={loading} onAddressCreate={onAddressCreate} />
          {addresses.map((address, index) => (
            <AddressBox
              key={index}
              loading={loading}
              address={address}
              onAddressDelete={onAddressDelete}
              onAddressEdit={onAddressEdit}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddressesPresentational;
