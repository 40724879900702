import { useEffect, useState } from 'react';
import { ArrayParam, NumberParam, useQueryParams } from 'use-query-params';
import {
  ORDER_CANCELED_STATUSES,
  ORDER_COMPLETED_STATUSES,
  ORDER_PROCESSING_STATUSES,
} from '../../lib/constants';
import { OrderStatus } from '../../lib/enums';
import {
  count as countOrders,
  IOrder,
  list as listOrders,
} from '../../lib/fetch/orders';
import { getDateFromFilter } from '../../lib/utils/order';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import MyOrdersPresentational from './MyOrdersPresentational';

const { REACT_APP_DEFAULT_PAGE_SIZE } = process.env;

export interface IMyOrdersQuery {
  statuses?: OrderStatus[];
  dateFilter?: number;
}

const defaultFilters = {
  dateFilter: 2,
};

const MyOrders = () => {
  const [{ token, user }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { id: userId } = user!;
  const [query, setQuery] = useQueryParams({
    statuses: ArrayParam,
    dateFilter: NumberParam,
  });
  const [loading, setLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalsByStatus, setTotalsByStatus] = useState({
    all: 0,
    processing: 0,
    canceled: 0,
    completed: 0,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_PAGE_SIZE! || 25);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const { statuses, dateFilter } = (query || {}) as IMyOrdersQuery;

  useEffect(() => {
    setTimeout(() => !dateFilter && setQuery(defaultFilters));
  }, [dateFilter]);

  useEffect(() => {
    setPage(1);
  }, [pageSize, statuses, dateFilter]);

  useEffect(() => {
    if (!dateFilter) return;
    fetchCounts();
  }, [dateFilter]);

  useEffect(() => {
    if (!dateFilter) return;
    fetchOrders();
  }, [page, pageSize, statuses, dateFilter, query]);

  const fetchCounts = async () => {
    setCountLoading(true);
    const statusCountResponses = await Promise.all(
      [
        undefined,
        ORDER_PROCESSING_STATUSES,
        ORDER_CANCELED_STATUSES,
        ORDER_COMPLETED_STATUSES,
      ].map((statuses) =>
        countOrders(token!, {
          userId,
          minDate: getDateFromFilter(dateFilter),
          statuses,
        })
      )
    );

    const [all, processing, canceled, completed] = statusCountResponses.map(
      ({ data, error }) => {
        if (error || !data) return 0;
        const {
          meta: {
            pagination: { total },
          },
        } = data;
        return total;
      }
    );
    setCountLoading(false);

    setTotalsByStatus({
      all,
      processing,
      canceled,
      completed,
    });
  };

  const fetchOrders = async () => {
    setLoading(true);
    const { data: axiosData, error } = await listOrders(token!, {
      page,
      pageSize,
      userId,
      statuses,
      minDate: getDateFromFilter(dateFilter),
      sort: ['date:desc'],
    });
    setLoading(false);
    if (error || !axiosData) {
      setOrders([]);
      return dispatchAlertChange({ open: true });
    }
    const {
      data = [],
      meta: { pagination },
    } = axiosData;
    const { pageCount = 1, total = 0 } = pagination;
    setPageCount(pageCount);
    setTotal(total);
    setOrders(data);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <MyOrdersPresentational
      loading={loading}
      countLoading={countLoading}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      orders={orders}
      page={page}
      pageCount={pageCount}
      pageSize={pageSize}
      total={total}
      totalsByStatus={totalsByStatus}
      query={query as IMyOrdersQuery}
      onChangeQuery={setQuery}
    />
  );
};

export default MyOrders;
