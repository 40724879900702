import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { classNames } from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import { UrlUpdateType } from 'use-query-params';
import { IMyOrdersQuery } from '../../pages/MyOrders';
import { ORDER_FILTER_MONTHS } from '../../lib/utils/order';

interface IMyOrdersFilter {
  count: number;
  query: IMyOrdersQuery;
  onChangeQuery: (query: IMyOrdersQuery, urlUpdateType?: UrlUpdateType) => void;
}

export default function MyOrdersFilter(props: IMyOrdersFilter) {
  const { count, query, onChangeQuery = () => {} } = props;
  const { dateFilter } = query || {};
  const { t } = useTranslation('MY_ORDERS');

  const selected =
    ORDER_FILTER_MONTHS.find(({ id }) => id === dateFilter) ||
    ORDER_FILTER_MONTHS[3];

  return (
    <div className="flex items-center">
      <Listbox
        value={selected}
        onChange={(month) => onChangeQuery({ dateFilter: month.id })}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="mr-2 block text-sm text-gray-800">
              <span className="font-semibold">{count}</span>{' '}
              {t('ORDERS_PLACED')}
            </Listbox.Label>
            <div className="relative flex-1">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-gray-100 py-2 pl-3 pr-10 text-left focus:outline-none sm:text-sm">
                <span className="block truncate">{t(selected.name)}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {ORDER_FILTER_MONTHS.map((month) => (
                    <Listbox.Option
                      key={month.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-red-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-8 pr-4'
                        )
                      }
                      value={month}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {t(month.name)}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-red-600',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
