import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDrop from '../Form/DragAndDrop';
import FileUploadPrev from '../Form/FileUploadPrev';

interface IStep4 {
  files: File[];
  acceptedFiles: readonly string[];
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: File) => void;
}

const Step4: FC<IStep4> = (props) => {
  const {
    acceptedFiles = [],
    files = [],
    onFileChange = (_: File[]) => {},
    onFileDelete = (_: File) => {},
  } = props;
  const { t } = useTranslation('ACTIVATION_REQUEST');
  return (
    <div className="border border-gray-300 rounded-md px-6 py-6">
      <h3 className="text-2xl font-bold text-gray-900 mb-6">
        {t('STEP4_TITLE')}
      </h3>

      <div className="mb-6">
        <DragAndDrop
          acceptedFiles={acceptedFiles}
          onFileChange={onFileChange}
        />
      </div>

      {files.map((file, index) => (
        <FileUploadPrev key={index} file={file} onDelete={onFileDelete} />
      ))}
    </div>
  );
};

export default Step4;
