import { classNames } from '../../lib/utils';
import Spinner from '../Spinner';

interface IGreyButton {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const GreyButton = ({
  text,
  type = 'button',
  onClick = () => {},
  disabled = false,
  loading = false,
}: IGreyButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        'flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-grey-800 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 uppercase',
        disabled ? 'cursor-not-allowed' : 'hover:bg-gray-300'
      )}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default GreyButton;
