import { TermsTxt } from '../../components/Policy';

const TermsConditions = () => {
  return (
    <div className="min-h-screen py-10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <TermsTxt />
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
