import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../components/Form/InputPresentational';
import PrimaryButtonFull from '../../components/Buttons/PrimaryButtonFull';
import LightPrimaryButtonFull from '../../components/Buttons/LightPrimaryButtonFull';
import { ILoginForm } from './index';

interface ILoginPresentational {
  loading: boolean;
  onLogin: (formData: ILoginForm) => void;
}

const LoginPresentational = (props: ILoginPresentational) => {
  const { loading = false, onLogin = (_formData: ILoginForm) => {} } = props;
  const { t } = useTranslation('LOGIN');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>();

  return (
    <div className="min-h-screen flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <img
            className="h-7 w-auto"
            src="../images/trevi-logo-red.svg"
            alt="Trevi Ecommerce"
            title="Trevi Ecommerce"
          />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {t('SIGN_IN_TITLE')}
          </h2>

          <div className="mt-8">
            <form onSubmit={handleSubmit(onLogin)}>
              <div className="grid grid-cols-1 gap-6">
                <InputPresentational
                  label={t('USER')}
                  id="identifier"
                  autoComplete="email"
                  {...register('identifier', {
                    required: t('COMMON:REQUIRED') as string,
                  })}
                  hasError={!!errors.identifier}
                  helperText={errors.identifier?.message}
                />
                <InputPresentational
                  label={t('PASSWORD')}
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  {...register('password', {
                    required: t('COMMON:REQUIRED') as string,
                  })}
                  hasError={!!errors.password}
                  helperText={errors.password?.message}
                />
              </div>

              <div className="flex items-center justify-between mt-6 mb-6">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember-description"
                      type="checkbox"
                      defaultChecked
                      className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                      {...register('remember')}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="font-medium text-gray-700"
                    >
                      {t('REMEMBER_ME')}
                    </label>
                  </div>
                </div>

                <div className="text-sm">
                  <Link
                    to="/forgot"
                    className="font-medium text-red-600 hover:text-red-500"
                  >
                    {t('FORGOT')}
                  </Link>
                </div>
              </div>

              <PrimaryButtonFull
                loading={loading}
                text={t('LOGIN')}
                type="submit"
              />
            </form>

            <div className="my-6 relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  {t('REQUEST_ACTIVATION_TXT')}
                </span>
              </div>
            </div>

            <Link to="/activation-request">
              <LightPrimaryButtonFull text={t('REQUEST_ACTIVATION_BTN')} />
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="../images/login-img.jpg"
          alt="Trevi ecommerce"
          title="Trevi ecommerce"
        />
      </div>
    </div>
  );
};

export default LoginPresentational;
