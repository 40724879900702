import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../lib/enums';
import { me, update as updateMe } from '../../lib/fetch/auth';
import {
  ITecdocManufacturer,
  getTecdocManufacturers,
} from '../../lib/fetch/manufacturers';
import { useAlert } from '../AlertProvider';
import { AuthActionType, useAuth } from '../AuthProvider';

const DEFAULT_LANGUAGE = Language.FR;

export enum SettingsActionType {
  SetManufacturers = 'SetManufacturers',
  SetLanguage = 'SetLanguage',
}

export interface SettingsState {
  tecdocManufacturers: ITecdocManufacturer[];
  language: Language;
}

export interface SettingsAction {
  type: SettingsActionType;
  tecdocManufacturers?: ITecdocManufacturer[];
  language?: Language;
}

const INITIAL_SETTINGS_STATE = Object.freeze<SettingsState>({
  tecdocManufacturers: [],
  language: DEFAULT_LANGUAGE,
});

const reducer = (state: SettingsState, action: SettingsAction) => {
  const {
    type,
    tecdocManufacturers = [],
    language = DEFAULT_LANGUAGE,
  } = action;
  switch (type) {
    case SettingsActionType.SetManufacturers:
      return {
        ...state,
        tecdocManufacturers,
      };
    case SettingsActionType.SetLanguage:
      return {
        ...state,
        language,
      };
    default:
      return { ...state };
  }
};

const reset = () => ({ ...INITIAL_SETTINGS_STATE });

const SettingsContext = createContext<
  [SettingsState, (_action: SettingsAction) => void]
>([{ ...INITIAL_SETTINGS_STATE }, (_action: SettingsAction) => {}]);

const SettingsProvider = ({ children }: { children: React.ReactElement }) => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { i18n } = useTranslation();
  const isAuthenticated = !!token;
  const [state, dispatch] = useReducer(
    reducer,
    { ...INITIAL_SETTINGS_STATE },
    reset
  );

  const updateUserLanguage = async (language: Language) => {
    if (!user) return;
    const { error } = await updateMe(token!, {
      id: user.id,
      language,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    return refetchProfile(language);
  };

  const refetchProfile = async (language: Language) => {
    const { data: updatedUser, error } = await me(token!, language);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const setLanguageAndDispatch = (action: SettingsAction) => {
    const { type, language = DEFAULT_LANGUAGE } = action;
    switch (type) {
      case SettingsActionType.SetLanguage:
        if (language !== user?.language) {
          updateUserLanguage(language);
        }
        i18n.changeLanguage(language);
        break;
      default:
        break;
    }
    return dispatch(action);
  };

  useEffect(() => {
    if (!user) return;
    setLanguageAndDispatch({
      type: SettingsActionType.SetLanguage,
      language: user.language || DEFAULT_LANGUAGE,
    });
  }, [user]);

  useEffect(() => {
    isAuthenticated &&
      (async () => {
        const { error, data } = await getTecdocManufacturers(token!);
        if (error || !data) return;
        dispatch({
          type: SettingsActionType.SetManufacturers,
          tecdocManufacturers: data.data,
        });
      })();
  }, [isAuthenticated]);

  return (
    <SettingsContext.Provider value={[state, setLanguageAndDispatch]}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => useContext(SettingsContext);

export { SettingsProvider, useSettings };
