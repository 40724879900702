import { ShoppingCartIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';

interface IInputButtonPresentational {
  onSubmit: (inputValue: string) => void;
  placeholder: string;
  textButton: string;
  type: 'text' | 'number';
}

const InputButtonPresentational = (props: IInputButtonPresentational) => {
  const { onSubmit = () => {}, type = 'text', placeholder, textButton } = props;
  const { handleSubmit, register } = useForm();
  return (
    <form
      className="mt-1 flex rounded-md shadow-sm"
      onSubmit={handleSubmit(({ inputValue }) => onSubmit(inputValue))}
    >
      <input
        type={type}
        className="block w-full rounded-none rounded-l-md border-r-0 border-gray-300 text-xs focus:border-red-500 focus:ring-red-500"
        placeholder={placeholder}
        required
        {...register('inputValue', { required: true })}
      />
      <button
        type="submit"
        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-red-600 bg-red-600 px-6 py-2 text-sm font-normal text-white hover:bg-red-700 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500"
      >
        <ShoppingCartIcon className="h-5 w-5" aria-hidden="true" />
        <span className="hidden whitespace-nowrap text-xs sm:inline-block">
          {textButton}
        </span>
      </button>
    </form>
  );
};

export default InputButtonPresentational;
