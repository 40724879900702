import { ITecdocManufacturer } from '../fetch/manufacturers';

export const getTecdocManufacturerByName = (
  manufacturerName: string,
  tecdocManufacturers: ITecdocManufacturer[]
) => {
  return tecdocManufacturers.find(
    ({ mfrName }) => mfrName === manufacturerName
  );
};
export const getTecdocManufacturerById = (
  manufacturerId: number,
  tecdocManufacturers: ITecdocManufacturer[]
) => {
  return tecdocManufacturers.find(
    ({ dataSupplierId }) => dataSupplierId === manufacturerId
  );
};
