import Spinner from '../Spinner';

const PrimaryButtonFull = ({
  disabled = false,
  loading = false,
  onClick = () => {},
  text,
  type = 'button',
}: {
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: any) => void;
  text: string;
  type?: 'button' | 'submit';
}) => {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default PrimaryButtonFull;
