import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ISelectPresentational {
  label: string;
  id: string;
  name: string;
  autoComplete: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  options: readonly { name: string; value: string }[];
}

const SelectPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      autoComplete,
      hasError,
      helperText,
      disabled = false,
      options = [],
      ...rest
    }: ISelectPresentational,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const { t } = useTranslation();

    return (
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1 relative">
          <select
            ref={ref}
            id={id}
            name={name}
            autoComplete={autoComplete}
            className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
            {...rest}
          >
            {options.map(({ name, value }, index) => (
              <option value={value} key={index}>
                {t(name)}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {hasError && (
              <ExclamationCircleIcon
                className="h-5 w-5 m-5 text-red-500"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

export default SelectPresentational;
