import axios, { AxiosResponse } from 'axios';
import { ICartItemUpdateResult } from './index';

const { REACT_APP_CART_ITEMS_URL } = process.env;

const _delete = async (token: string, cartItem: { id: number }) => {
  const response = await axios.delete<{ data: ICartItemUpdateResult }>(
    `${REACT_APP_CART_ITEMS_URL!}/${cartItem.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default _delete;
