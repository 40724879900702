interface IInfoDetail {
  title: string;
  value: string;
}
interface IInfoDetailBoxProps {
  details: IInfoDetail[];
}

const InfoDetailBox = (props: IInfoDetailBoxProps) => {
  const { details } = props;

  return (
    <div className="w-full rounded-md border border-gray-200 bg-white py-2 font-medium">
      <dl className="divide-y divide-gray-200">
        {details.map(({ title, value }, key) => (
          <div key={key} className="py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="capitalize text-gray-500">{title}</dt>
            <dd className="mt-1 capitalize text-gray-900 sm:col-span-2 sm:mt-0">
              {value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default InfoDetailBox;
