import { useTranslation } from 'react-i18next';

const PrivacyPolicyTxt = () => {
  const { t } = useTranslation('PRIVACY');
  return (
    <div className="border border-gray-300 rounded-xl px-6 py-6 mb-6">
      <h3 className="text-2xl font-bold text-gray-800 mb-6">{t('TITLE')}</h3>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        INFORMATIVA SULLA PRIVACY
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        La presente Informativa Privacy descrive le modalità di acquisizione,
        utilizzo, trasmissione, conservazione e protezione dei dati personali,
        ed è resa ai sensi dell’art. 13 del Regolamento Ue 679/2016 (di seguito
        “GDPR”) a tutti coloro che si collegano al presente sito web (di seguito
        il “Sito”).
        <br />
        <br />
        Con l’espressione “dato personale” si intende qualunque informazione
        relativa ad una persona fisica, identificata o identificabile, anche
        indirettamente, mediante riferimento a qualsiasi altra informazione
        (art. 4., par. 4 GDPR).
        <br />
        <br />
        Utilizzando i nostri Servizi e/o registrando un account, Lei accetta
        quanto previsto nelle condizioni della presente Privacy Policy per gli
        utenti e acconsente alla acquisizione, alla trasmissione, alla
        conservazione e alla protezione delle informazioni personali così come
        descritto nel presente documento.
        <br />
        <br />
        L’informativa si ispira alle raccomandazioni del{' '}
        <i>“Gruppo di lavoro ex art 29”</i> della direttiva 95/46 per la
        protezione dei dati personali al fine di individuare alcuni requisiti
        minimi per la raccolta di dati personali on-line, e, in particolare, le
        modalità, i tempi e la natura delle informazioni che i titolari del
        trattamento devono fornire agli utenti quando questi si collegano a
        pagine web, indipendentemente dagli scopi del collegamento.
        <br />
        <br />
        Non fornendo le informazioni richieste Lei potrebbe non essere in grado
        di usufruire di tutti i servizi offerti dal Sito.
        <br />
        <br />
        Ci si riserva di modificare il presente documento in qualsiasi momento
        pubblicando le relative revisioni sul Sito, anche al fine del costante
        adeguamento ai mutamenti normativi. Eventuali modifiche sostanziali alla
        presente informativa saranno rese note mediante comunicazioni sulla home
        page del Sito.
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        TITOLARI DEL TRATTAMENTO E FINALITÀ
      </h4>
      <p className="mb-4 font-light text-sm text-gray-600">
        Titolare del Trattamento (di seguito il “Titolare”), ai sensi dell’art.
        4 par. 7 del GDPR, è CATI S.p.a. con sede legale in Venaria (TO), Via
        Pretegiani nr. 27 P.IVA IT03748340019 C.F IT03748340019 REA: TO-584370 ,
        in persona del legale rappresentante pro tempore.
        <br />
        <br />
        Cati S.p.a. sarà Titolare dei dati forniti dagli interessati per le
        seguenti finalità:
      </p>
      <ul className="list-decimal pl-4 mb-4 font-light text-sm text-gray-600 space-y-3">
        <li>
          gestire l’account degli utenti in caso di registrazione al Sito per
          fruire dei relativi servizi;
          <br />
          <br />
          Il conferimento dei dati per la finalità di cui al punto n. 1, in
          quanto necessario per dare corso alla vendita e consegna dei Prodotti
          acquistati sul Sito o per la registrazione allo stesso, è obbligatorio
          ed il rifiuto a prestare il relativo consenso al Trattamento renderà
          impossibile la registrazione al Sito e l’accesso ai servizi richiesti
        </li>
        <li>
          permettere l’accesso a servizi specifici ed ulteriori, quali la
          ricezione di newsletter per comunicazione di offerte promozionali e
          altri servizi informativi, ovvero per lo svolgimento di attività
          statistiche e/o di ricerche di mercato;
        </li>
        <li>
          attività di marketing: i dati personali verranno utilizzati per
          sottoporre agli interessati, anche a mezzo e-mail, comunicazioni
          commerciali al fine di informarli in merito alle attività di
          promozione e sviluppo di nuovi prodotti;
        </li>
        <li>
          gestione delle sezioni “inviaci la tua comunicazione” e “lavora con
          noi” presenti nel Sito: i dati forniti verranno utilizzati per
          rispondere alla richieste inoltrate e, in caso di invio di curricula,
          per valutare la candidatura dell’utente per opportunità professionali
          all’interno dell’organizzazione della società Titolare.
        </li>
      </ul>
      <p className="mb-6 font-light text-sm text-gray-600">
        Il conferimento di dati da parte dell’utente per le finalità di cui ai
        punti 2, 3 e 4 è sempre facoltativo benché necessario per il
        perseguimento delle predette finalità.
        <br />
        <br />
        Per tutte le finalità di cui sopra, il Titolare potrà incaricare dei
        fornitori esterni ai quali verranno trasmessi solo ed esclusivamente i
        dati strettamente necessari all’espletamento dell’incarico.
        <br />
        <br />I Fornitori agiranno in qualità di Responsabili del Trattamento
        anche nominati ai sensi dell’art. 28 del GDPR, il cui elenco è
        disponibile facendone richiesta al Titolare del trattamento, utilizzando
        i contatti indicati nella presente policy.
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        LUOGO DI TRATTAMENTO DEI DATI
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        I trattamenti connessi ai servizi web di questo sito hanno luogo presso
        la sede di CATI S.p.a. in Venaria (TO), Via Pretegiani nr. 27 e sono
        curati solo da personale tecnico dell’Ufficio incaricato del
        trattamento.
        <br />
        <br />
        Il luogo di conservazione dei dati, e quindi del server Web aziendale, è
        presso il Data Center di Aruba S.p.a. in Arezzo (AR), Via Piero Gobetti
        nr. 96.
        <br />
        <br />
        Le informazioni generate attraverso l’iscrizione alla sezione
        “newsletter” vengono raccolte da Mail Up.
        <br />
        <br />
        Per ulteriori informazioni, è possibile visionare la{' '}
        <a
          href="https://www.mailup.it/informativa-privacy/"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          privacy policy
        </a>{' '}
        di detta società.
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        MODALITÀ DEL TRATTAMENTO
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        Il Titolare ed i suoi Responsabili e/o Incaricati tratteranno i dati
        personali degli utenti adottando le opportune misure di sicurezza volte
        ad impedire l’accesso, la divulgazione, la modifica o la distruzione non
        autorizzate dei dati personali.
        <br />
        <br />
        In relazione alle finalità indicate in precedenza, il Trattamento
        avverrà mediante strumenti informatici e/o telematici, con modalità
        organizzative e con logiche strettamente correlate alle finalità
        indicate e, comunque, in modo da garantire la sicurezza e la
        riservatezza dei dati.
        <br />
        <br />
        Il Trattamento potrà essere svolto per conto del Titolare, con le
        medesime modalità e criteri suindicati, da personale autorizzato
        coinvolto nell’organizzazione del Sito (personale amministrativo,
        commerciale, marketing, legali, amministratori di sistema), ovvero da
        soggetti esterni (come ad esempio fornitori di servizi tecnici terzi,
        corrieri postali, hosting provider, società informatiche, agenzie di
        comunicazione) opportunamente nominati Responsabili, ai sensi dell’art.
        28 del GDPR.
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        PERIODO DI CONSERVAZIONE DEI DATI
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        I dati personali degli utenti saranno conservati per il tempo necessario
        a perseguire le finalità per i quali gli stessi sono stati acquisti.
        <br />
        <br />
        Il periodo di conservazione dei dati dipende dalle finalità per cui
        vengono trattati e pertanto potrebbe variare. I criteri utilizzati per
        determinare il periodo di conservazione applicabile sono i seguenti: la
        conservazione dei dati personali avverrà per il tempo necessario a (i)
        gestire gli ordini e svolgere le attività connesse; (ii) gestire
        l’account degli utenti registrati al sito, ivi comprese le attività di
        marketing e gestione newsletter; (iii) svolgere le attività di hosting e
        manutenzione del sito; (iv) ricercare posizioni professionali in linea
        con i profili dei candidati come risultanti dai curricula e dalle
        informazioni inviate tramite il form; (v) far valere diritti in sede
        giudiziaria nonché (vi) per il tempo previsto da norme di legge
        applicabili.
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        TIPOLOGIA DI DATI TRATTATI
      </h4>
      <p className="mb-4 font-light text-sm text-gray-600">
        I Dati personali oggetto di trattamento da parte del Titolare sono
        principalmente costituiti da:
      </p>
      <ul className="list-decimal pl-4 mb-4 font-light text-sm text-gray-600 space-y-3">
        <li>Dati Di Navigazione</li>
      </ul>
      <p className="mb-4 font-light text-sm text-gray-600">
        I sistemi informatici e le procedure software preposte al funzionamento
        di questo Sito acquisiscono, nel corso del loro normale esercizio,
        alcuni dati personali la cui trasmissione è implicita nell’uso dei
        protocolli di comunicazione di Internet.
        <br />
        <br />
        Si tratta di informazioni che non sono raccolte per essere associate a
        interessati identificati, ma che per loro stessa natura potrebbero,
        attraverso elaborazioni ed associazioni con dati detenuti da terzi,
        permettere di identificare gli utenti.
        <br />
        <br />
        In questa categoria di dati rientrano gli indirizzi IP o i nomi a
        dominio dei computer utilizzati dagli utenti che si connettono al sito,
        gli indirizzi in notazione URI (Uniform Resource Identifier) delle
        risorse richieste, l’orario della richiesta, il metodo utilizzato nel
        sottoporre la richiesta al server, la dimensione del file ottenuto in
        risposta, il codice numerico indicante lo stato della risposta data dal
        server (buon fine, errore, ecc.), il Paese di provenienza, le
        caratteristiche del browser e del sistema operativo utilizzato
        dall’utente ed altri parametri relativi al sistema operativo e
        all’ambiente informatico dell’utente.
        <br />
        <br />
        Questi dati vengono utilizzati al solo fine di ricavare informazioni
        statistiche anonime sull’uso del sito e per controllarne il corretto
        funzionamento e vengono cancellati immediatamente dopo l’elaborazione.
      </p>
      <ul className="list-decimal pl-4 mb-4 font-light text-sm text-gray-600 space-y-3">
        <li>Dati Forniti Volontariamente Dall’utente</li>
      </ul>
      <p className="mb-4 font-light text-sm text-gray-600">
        L’invio facoltativo, esplicito e volontario di richieste tramite i
        format e posta elettronica agli indirizzi indicati sul Sito comporta la
        successiva acquisizione dell’indirizzo del mittente, necessario per
        rispondere alle richieste, nonché degli eventuali altri dati personali
        inseriti nella richiesta. Specifiche informative di sintesi verranno
        progressivamente riportate o visualizzate nelle pagine del Sito
        predisposte per particolari servizi a richiesta.
      </p>
      <ul className="list-decimal pl-4 mb-4 font-light text-sm text-gray-600 space-y-3">
        <li>Dati forniti da terze parti</li>
      </ul>
      <p className="mb-4 font-light text-sm text-gray-600">
        I dati forniti da terze parti sono tutti i dati personali che i Titolari
        raccolgono da altre fonti (società di servizi postali, corrieri, società
        di data entry, ecc.) per svolgere i servizi del Sito. Anche in questo
        caso, qualsiasi soggetto interessato, nei limiti di legge, può decidere
        di non permettere il trattamento di tutti o parte dei suoi dati, sebbene
        tale esclusione potrebbe limitare o escludere l’uso di parte dei servizi
        del Sito.
        <br />
        <br />
        Esempi di dati forniti da terze parti sono i dati relativi agli
        aggiornamenti o correzioni ricevuti in occasione delle consegne da parte
        dei corrieri o dei partner trasportatori o di terzi.
      </p>
      <ul className="list-decimal pl-4 mb-6 font-light text-sm text-gray-600 space-y-3">
        <li>Cookies</li>
      </ul>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        DIRITTI DEGLI INTERESSATI
      </h4>
      <p className="mb-4 font-light text-sm text-gray-600">
        I soggetti cui si riferiscono i dati personali potranno in qualunque
        momento, ai sensi degli articoli 15-22 del GDPR, esercitare il diritto
        di:
      </p>
      <ul className="list-decimal pl-4 mb-6 font-light text-sm text-gray-600 space-y-3">
        <li>
          ottenere dal titolare del Trattamento la conferma che sia o meno in
          corso un trattamento di dati personali che la riguardano e, in tal
          caso, di ottenerne l’accesso;
        </li>
        <li>
          ottenere le indicazioni circa le finalità e le modalità del
          trattamento, le categorie di dati personali fornite, i destinatari e/o
          le categorie di destinatari ai quali i dati sono stati o saranno
          comunicati e, quando possibile, il periodo di conservazione;
        </li>
        <li>
          ottenere la rettifica e l’integrazione la cancellazione dei dati;
        </li>
        <li>ottenere la limitazione del trattamento;</li>
        <li>
          ottenere la portabilità dei dati, ossia riceverli dal Titolare del
          trattamento, ai sensi dell’art. 20 del GDPR 2016/679, “in un formato
          strutturato, di uso comune e leggibile da dispositivo automatico” e
          trasmetterli ad altro Titolare del trattamento senza impedimenti;
        </li>
        <li>
          opporsi al trattamento in qualsiasi momento ed anche nel caso di
          trattamento per finalità di marketing diretto;
        </li>
        <li>
          revocare il consenso in qualsiasi momento senza pregiudicare la
          liceità del trattamento basato sul consenso prestato prima della
          revoca;
        </li>
      </ul>
      <p className="mb-6 font-light text-sm text-gray-600">
        Le richieste andranno rivolte ai Titolari del Trattamento via e-mail
        all’ indirizzo:
        <br />
        <br />
        <span className="font-normal">
          Cati S.p.a., Via Pretegiani 27, 10078, Venaria Reale (TO)
        </span>
        ; e-mail: info@cati.it
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        DIRITTO DI RECLAMO
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        Gli interessati che ritengono che il Trattamento dei dati personali a
        loro riferiti effettuato attraverso questo sito avvenga in violazione di
        quanto previsto dal Regolamento hanno il diritto di proporre reclamo al
        Garante, come previsto dall’art. 77 del Regolamento stesso, o di adire
        le opportune sedi giudiziarie (art. 79 del Regolamento).
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        ACQUISTI E PAGAMENTI
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        Per l’effettuazione degli acquisti on line, sarà possibile utilizzare,
        quali modalità di pagamento, PayPal nonché le carte di credito dei
        circuiti Visa e Mastercard.
        <br />
        <br />
        Quanto a PayPal, è un servizio di pagamento fornito da PayPal (Europe)
        S.à r.l. et Cie, S.C.A. (“PayPal”), che consente all’Utente di
        effettuare pagamenti online utilizzando le proprie credenziali PayPal.
        Selezionando il pagamento con PayPal, l’utente verrà reindirizzato ad
        una pagina sul server sicuro PayPal dove inserirà il proprio indirizzo
        email e la password PayPal ed effettuerà il pagamento utilizzando il
        conto PayPal e/o le carte ad esso associate in precedenza.
        <br />
        <br />
        Tutti i dati forniti dall’utente ai fini della procedura di pagamento
        verranno trattati esclusivamente e autonomamente da PayPal (Europe) S.à
        r.l. et Cie, S.C.A. secondo le condizioni privacy visibili al link:
        https://www.paypal.com/it/webapps/mpp/ua/privacy-full.
        <br />
        <br />
        In caso di pagamento con Visa o con Mastercard, i dati forniti
        dall’utente ai fini della procedura di pagamento verranno trattati
        esclusivamente e autonomamente dai rispettivi Titolari del trattamento
        secondo le condizioni privacy visibili al link:
        <br />
        <br />
        VISA -{' '}
        <a
          href="https://www.visaeurope.com/privacy/"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://www.visaeurope.com/privacy/
        </a>
        <br />
        <br />
        MASTERCARD -{' '}
        <a
          href="https://www.mastercard.it/it-it/mastercard/cosa-facciamo/privacy.html"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://www.mastercard.it/it-it/mastercard/cosa-facciamo/privacy.html
        </a>
      </p>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        INTERAZIONE CON SOCIAL NETWORK E PIATTAFORME ESTERNE
      </h4>
      <p className="font-light text-sm text-gray-600">
        Questo tipo di servizi permetterà di effettuare interazioni con i social
        network, o con altre piattaforme esterne, direttamente dalle pagine di
        questo Sito. Le interazioni e le informazioni acquisite da questo Sito
        saranno in ogni caso soggette alle impostazioni privacy dell’Utente
        relative ad ogni social network.
        <br />
        <br />I dati inseriti tramite la piattaforma di Facebook saranno
        sottoposti alle condizioni presenti nella policy visibile al seguente
        link:{' '}
        <a
          href="https://www.facebook.com/privacy/explanation"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://www.facebook.com/privacy/explanation
        </a>
        .
        <br />
        <br />I dati inseriti tramite la piattaforma di Instagram saranno
        sottoposti alle condizioni presenti nella policy visibile al seguente
        link:{' '}
        <a
          href="https://help.instagram.com/519522125107875"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://help.instagram.com/519522125107875
        </a>
        <br />
        <br />I dati inseriti tramite la piattaforma You Tube saranno sottoposti
        alle condizioni presenti nella policy visibile al seguente link:{' '}
        <a
          href="https://support.google.com/youtube/answer/2801895?hl=it"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://support.google.com/youtube/answer/2801895?hl=it
        </a>
        <br />
        <br />I dati inseriti tramite la piattaforma Linkedin saranno sottoposti
        alle condizioni presenti nella policy visibile al seguente link:{' '}
        <a
          href="https://www.linkedin.com/legal/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://www.linkedin.com/legal/privacy-policy
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicyTxt;
