import { useTranslation } from 'react-i18next';

export default function MadeOrderBadge() {
  const { t } = useTranslation('PRODUCTS');

  return (
    <div>
      <span className="inline-flex items-center text-sm font-medium text-orange-400">
        <div className="h-3 w-3 bg-orange-400 mr-2 rounded-full" />
        {t('MADE_ORDER')}
      </span>
    </div>
  );
}
