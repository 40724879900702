import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputPresentational from '../../components/Form/InputPresentational';
import PrimaryButtonFull from '../../components/Buttons/PrimaryButtonFull';
import { FixedAlert } from '../../components/Alert';
import BackButton from '../../components/Buttons/BackButton';
import { AlertType } from '../../providers/AlertProvider';
import { IResetForm } from './index';

interface IResetPresentational {
  loading: boolean;
  result?: AlertType;
  resultTitle?: string;
  resultDescription?: string;
  onReset: (formData: IResetForm) => void;
}

const ResetPresentational = (props: IResetPresentational) => {
  const {
    loading = false,
    result,
    resultTitle,
    resultDescription,
    onReset = (_formData: IResetForm) => {},
  } = props;
  const { t } = useTranslation('RESET');
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IResetForm>();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    code && setValue('code', code);
  }, [code]);

  return (
    <div className="min-h-screen flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mb-10">
            <BackButton text={t('FORGOT:BACK_LOGIN')} to="/login" />
          </div>
          <img
            className="h-7 w-auto"
            src="../images/trevi-logo-red.svg"
            alt="Trevi Ecommerce"
            title="Trevi Ecommerce"
          />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {t('RESET')}
          </h2>
          <p className="mt-2 text-sm text-gray-600">{t('DESCRIPTION')}</p>

          <div className="mt-8 mb-6">
            <form onSubmit={handleSubmit(onReset)}>
              <div className="grid grid-cols-1 gap-6 mb-6">
                {/*<InputPresentational*/}
                {/*  label={t('CODE')}*/}
                {/*  id="code"*/}
                {/*  disabled*/}
                {/*  autoComplete="one-time-code"*/}
                {/*  {...register('code', {*/}
                {/*    required: t('COMMON:REQUIRED') as string,*/}
                {/*  })}*/}
                {/*  hasError={!!errors.code}*/}
                {/*  helperText={errors.code?.message}*/}
                {/*/>*/}
                <InputPresentational
                  label={t('NEW_PASSWORD')}
                  id="password"
                  type="password"
                  autoComplete="new-password"
                  {...register('password', {
                    required: t('COMMON:REQUIRED') as string,
                    minLength: {
                      message: t('VALIDATION_MIN') as string,
                      value: 6,
                    },
                    maxLength: {
                      message: t('VALIDATION_MAX') as string,
                      value: 20,
                    },
                  })}
                  hasError={!!errors.password}
                  helperText={errors.password?.message}
                />
                <InputPresentational
                  label={t('REPEAT_PASSWORD')}
                  id="passwordConfirmation"
                  type="password"
                  autoComplete="new-password"
                  {...register('passwordConfirmation', {
                    required: t('COMMON:REQUIRED') as string,
                    validate: (passwordConfirmation: string) => {
                      return (
                        passwordConfirmation === getValues('password') ||
                        (t('VALIDATION_CONFIRMATION') as string)
                      );
                    },
                  })}
                  hasError={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message}
                />
                <PrimaryButtonFull
                  type="submit"
                  loading={loading}
                  text={t('RESET')}
                />
              </div>
            </form>
          </div>
          <FixedAlert
            type={result}
            title={resultTitle}
            description={resultDescription}
          />
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="../images/login-img.jpg"
          alt="Trevi ecommerce"
          title="Trevi ecommerce"
        />
      </div>
    </div>
  );
};

export default ResetPresentational;
