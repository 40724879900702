import axios, { AxiosResponse } from 'axios';
import { ITecdocManufacturer } from '.';

const { REACT_APP_PRODUCTS_URL } = process.env;

interface IGetTecdocManufacturersResponse {
  data: ITecdocManufacturer[];
}

const getTecdocManufacturers = async (token: string) => {
  const response = await axios.get<IGetTecdocManufacturersResponse>(
    `${REACT_APP_PRODUCTS_URL!}/getTecdocManufacturers`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getTecdocManufacturers;
