import axios, { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { IStrapiFile } from '../../interfaces';

const { REACT_APP_FIND_FILES_URL } = process.env;
const MIN_UUID_LENGTH = 36;

const list = async (token: string, uuid: string) => {
  const response = await axios.get<IStrapiFile[]>(REACT_APP_FIND_FILES_URL!, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      _q: uuid.length >= MIN_UUID_LENGTH ? uuid : uuidv4(),
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
