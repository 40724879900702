import { Link } from 'react-router-dom';
import {
  // ChevronDownIcon,
  CursorClickIcon,
  MailIcon,
  PhoneIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import packageDotJSON from '../../../package.json';

const { version } = packageDotJSON;

const Footer = () => {
  const { t } = useTranslation('FOOTER');
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-full mx-auto py-12 px-4 lg:py-16">
        <div className="grid pb-8 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:gap-8">
          <div>
            <img
              className="h-7 w-auto"
              src="../images/trevi-logo-white.svg"
              alt="Trevi Ecommerce"
              title="Trevi Ecommerce"
            />
            <ul className="mt-4 space-y-4">
              <li className="text-sm font-light uppercase text-gray-300">
                {t('TITLE_FOOTER')}
              </li>
              <li className="text-sm text-gray-300">
                Via Pretegiani 27, 10078 Venaria (TO)
              </li>
            </ul>
          </div>

          <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
              {t('CONTACTS')}
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <a
                  href="tel:+390114073711"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center text-sm text-gray-300 hover:text-white"
                >
                  <PhoneIcon className="mr-2 h-4 w-4" /> +39 011 4073711
                </a>
              </li>
              <li>
                <a
                  href="mailto:info@trevi.parts"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center text-sm text-gray-300 hover:text-white"
                >
                  <MailIcon className="mr-2 h-4 w-4" /> info@trevi.parts
                </a>
              </li>
              <li>
                <a
                  href="https://www.catispa.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center text-sm text-gray-300 hover:text-white"
                >
                  <CursorClickIcon className="mr-2 h-4 w-4" /> www.catispa.com
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-12 md:mt-12 lg:mt-0">
            <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
              {t('COMPANY_TITLE')}
            </h3>
            <ul className="mt-4 space-y-4">
              <li className="text-sm text-gray-300">P.IVA IT03748340019</li>
              <li className="text-sm text-gray-300">
                <a
                  href="mailto:amministrazione.cati@pec.cati.it"
                  target="_blank"
                  rel="noreferrer"
                >
                  PEC: amministrazione.cati@pec.cati.it
                </a>
              </li>
              <li className="text-sm text-gray-300">
                Registro delle Imprese di Torino REA TO-584370
              </li>
              <li className="text-sm text-gray-300">
                Capitale sociale 1.560.000,00 euro, interamente versato
              </li>
            </ul>
          </div>

          <div className="mt-12 md:mt-12 lg:mt-0">
            {/*<h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">*/}
            {/*  {t('LANGUAGE')}*/}
            {/*</h3>*/}
            {/*<form className="mt-4 sm:max-w-xs">*/}
            {/*  <fieldset className="w-full">*/}
            {/*    <label htmlFor="language" className="sr-only">*/}
            {/*      Language*/}
            {/*    </label>*/}
            {/*    <div className="relative">*/}
            {/*      <select*/}
            {/*        id="language"*/}
            {/*        name="language"*/}
            {/*        className="appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md py-2 pl-3 pr-10 text-base text-white focus:outline-none focus:ring-white focus:border-white sm:text-sm"*/}
            {/*        defaultValue="English"*/}
            {/*      >*/}
            {/*        <option>Italian</option>*/}
            {/*        <option>English</option>*/}
            {/*        <option>French</option>*/}
            {/*        <option>German</option>*/}
            {/*        <option>Japanese</option>*/}
            {/*        <option>Spanish</option>*/}
            {/*      </select>*/}
            {/*      <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">*/}
            {/*        <ChevronDownIcon*/}
            {/*          className="h-4 w-4 text-white"*/}
            {/*          aria-hidden="true"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </fieldset>*/}
            {/*</form>*/}
            {/*<h3 className="mt-4 text-sm font-semibold text-gray-400 tracking-wider uppercase">*/}
            {/*  {t('SUPPORT')}*/}
            {/*</h3>*/}
            {/*<ul className="mt-4 space-y-4">*/}
            {/*  <li>*/}
            {/*    <Link*/}
            {/*      to="/faq"*/}
            {/*      className="text-sm text-gray-300 hover:text-white flex items-center"*/}
            {/*    >*/}
            {/*      {t('FAQ')}*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <Link*/}
            {/*      to="/shipping"*/}
            {/*      className="text-sm text-gray-300 hover:text-white flex items-center"*/}
            {/*    >*/}
            {/*      {t('SHIPPING_TERMS')}*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
        </div>

        <div className="mt-8 flex flex-wrap items-center justify-center border-t border-gray-700 pt-8">
          <p className="text-center text-sm text-gray-400">
            &copy;{' '}
            <a
              href="https://www.palmabit.com/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-white"
            >
              Palmabit
            </a>{' '}
            {new Date().getFullYear()}, Inc. All rights reserved.
          </p>

          <span className="ml-4 h-6 w-px bg-gray-400" aria-hidden="true" />

          <p className="ml-4 text-center text-sm text-gray-400">
            <Link to="/profile/privacy-policy" className="hover:text-white">
              {t('PRIVACY:TITLE')}
            </Link>
          </p>

          <span className="ml-4 h-6 w-px bg-gray-400" aria-hidden="true" />

          <p className="ml-4 text-center text-sm text-gray-400">
            <Link to="/profile/terms-conditions" className="hover:text-white">
              {t('TERMS:TITLE')}
            </Link>
          </p>

          <span className="ml-4 h-6 w-px bg-gray-400" aria-hidden="true" />

          <p className="ml-4 text-center text-sm text-gray-400">v. {version}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
