import { Language, OrderStatus, PaymentMethod } from '../enums';

export const DEFAULT_IMAGE_PLACEHOLDER = '/images/photo-not-available.jpg';
export const HIDDEN_MANUFACTURERS_NAMES = ['UNKNOW', 'UNKNOWN'];
export const ORDER_PROCESSING_STATUSES = [
  OrderStatus.Processing,
  OrderStatus.Paid,
];
export const ORDER_CANCELED_STATUSES = [OrderStatus.Canceled];
export const ORDER_COMPLETED_STATUSES = [OrderStatus.Delivered];
export const AVAILABLE_LANGUAGES = [
  /*Language.EN, */ Language.FR /*, Language.IT*/,
];

export const ALLOWED_PAYMENT_METHODS = [PaymentMethod.WireTransfer];
export const IS_ONLY_WIRE_TRANSFER_ENABLED = !ALLOWED_PAYMENT_METHODS.filter(
  (paymentMethod) => paymentMethod !== PaymentMethod.WireTransfer
).length;
