import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import {
  AddressButton,
  GreyButton,
  PrimaryButton,
} from '../../../../../components/Buttons';
import Address from './Address';
import { IAddress } from '../../../../../lib/interfaces';

interface IDeleteAddress {
  loading: boolean;
  address: IAddress;
  onAddressDelete: (_: IAddress) => void;
}

const DeleteAddress = (props: IDeleteAddress) => {
  const {
    loading = false,
    address,
    onAddressDelete = (_: IAddress) => {},
  } = props;
  const { t } = useTranslation('PROFILE');
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const onDeleteConfirm = () => {
    onAddressDelete(address);
    setOpen(false);
  };

  return (
    <>
      <AddressButton text={t('COMMON:DELETE')} onClick={() => setOpen(true)} />

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="leading-6 text-md font-bold text-gray-800"
                  >
                    {t('CONFIRM_DELETE_ADDRESS')}
                  </Dialog.Title>

                  <div className="mt-6 py-4 px-6 bg-gray-50">
                    <Address address={address} />
                  </div>
                </div>

                <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <GreyButton
                    text={t('COMMON:NO')}
                    onClick={() => setOpen(false)}
                  />
                  <PrimaryButton
                    text={t('COMMON:YES')}
                    loading={loading}
                    onClick={onDeleteConfirm}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteAddress;
