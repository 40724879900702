import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputPresentational from '../../components/Form/InputPresentational';
import PrimaryButtonFull from '../../components/Buttons/PrimaryButtonFull';
import { FixedAlert } from '../../components/Alert';
import BackButton from '../../components/Buttons/BackButton';
import { AlertType } from '../../providers/AlertProvider';

interface IForgotPresentational {
  loading: boolean;
  result?: AlertType;
  resultTitle?: string;
  resultDescription?: string;
  onForgot: (formData: { email: string }) => void;
}

const ForgotPresentational = (props: IForgotPresentational) => {
  const {
    loading = false,
    result,
    resultTitle,
    resultDescription,
    onForgot = (_: { email: string }) => {},
  } = props;
  const { t } = useTranslation('FORGOT');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();

  return (
    <div className="min-h-screen flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mb-10">
            <BackButton text={t('BACK_LOGIN')} to="/login" />
          </div>
          <img
            className="h-7 w-auto"
            src="../images/trevi-logo-red.svg"
            alt="Trevi Ecommerce"
            title="Trevi Ecommerce"
          />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {t('TITLE')}
          </h2>
          <p className="mt-2 text-sm text-gray-600">{t('DESCRIPTION')}</p>

          <div className="mt-8 mb-6">
            <form onSubmit={handleSubmit(onForgot)}>
              <div className="grid grid-cols-1 gap-6 mb-6">
                <InputPresentational
                  label={t('COMMON:EMAIL')}
                  id="email"
                  type="email"
                  autoComplete="email"
                  {...register('email', {
                    required: t('COMMON:REQUIRED') as string,
                  })}
                  hasError={!!errors.email}
                  helperText={errors.email?.message}
                />
                <PrimaryButtonFull
                  loading={loading}
                  text={t('RESET')}
                  type="submit"
                />
              </div>
            </form>
          </div>
          <FixedAlert
            type={result}
            title={resultTitle}
            description={resultDescription}
          />
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="../images/login-img.jpg"
          alt="Trevi ecommerce"
          title="Trevi ecommerce"
        />
      </div>
    </div>
  );
};

export default ForgotPresentational;
