import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { me, update } from '../../lib/fetch/auth';
import EditProfilePresentational from './EditProfilePresentational';
import { IUser } from '../../lib/interfaces';
import i18n from '../../i18n';

const EditProfile = () => {
  const { t } = useTranslation('PROFILE_EDIT');
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const { uuid, addresses = [] } = user || {};
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const navigate = useNavigate();
  const lang = i18n.languages[0];

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!, lang);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const onEdit = async (user: IUser) => {
    setLoading(true);
    const { error } = await update(token!, {
      ...user,
      addresses: addresses.map((address) => address.id),
      username: user.email,
      uuid: uuid!,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    refetchProfile();
    setLoading(false);
    dispatchAlertChange({
      message: t('UPDATE_SUCCESS'),
      open: true,
      type: AlertType.Success,
    });
    navigate('/profile');
  };

  return (
    <EditProfilePresentational loading={loading} user={user!} onEdit={onEdit} />
  );
};

export default EditProfile;
