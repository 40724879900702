import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {
  Addresses,
  CookiePolicy,
  Documents,
  GeneralData,
  PrivacyPolicy,
  ReturnInfo,
  TermsConditions,
  VerticalNavigationProfile,
} from './components';
import { IStrapiFile, IUser } from '../../lib/interfaces';
interface IProfilePresentational {
  acceptedFiles: readonly string[];
  files: IStrapiFile[];
  user: IUser;
  loading: boolean;
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: IStrapiFile) => void;
}

const ProfilePresentational = (props: IProfilePresentational) => {
  const { t } = useTranslation('PROFILE');
  const { acceptedFiles, files, user, loading, onFileChange, onFileDelete } =
    props;
  return (
    <div className="min-h-screen">
      <Header />

      <div className="flex-grow w-full mx-auto lg:px-4 lg:flex">
        <div className="flex-1 min-w-0 min-h-screen bg-white lg:flex">
          <div className="lg:flex-shrink-0 lg:w-64 xl:w-72 bg-white">
            <div className="h-full pl-4 pr-4 pt-6 pb-0 sm:pl-4 lg:pb-6 lg:pl-0 lg:pr-0">
              <div className="h-full lg:w-64 xl:w-72">
                <div className="border-b border-gray-200 pb-2 mb-6">
                  <h2 className="text-xl font-bold text-gray-900">
                    {t('TITLE')}
                  </h2>
                </div>
                <VerticalNavigationProfile />
              </div>
            </div>
          </div>

          <Routes>
            <Route
              index
              element={
                <GeneralData
                  acceptedFiles={acceptedFiles}
                  files={files}
                  user={user}
                  loading={loading}
                  onFileChange={onFileChange}
                  onFileDelete={onFileDelete}
                />
              }
            />
            <Route path="addresses" element={<Addresses />} />
            <Route path="return-info" element={<ReturnInfo />} />
            <Route path="documents" element={<Documents />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePresentational;
