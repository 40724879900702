import axios, { AxiosResponse } from 'axios';

const { REACT_APP_UPLOAD_URL } = process.env;

interface IUpload {
  files: File[];
  path?: string;
  refId: number;
  ref: string;
  source?: string;
  field: string;
}

const upload = async (data: IUpload) => {
  const formData = new FormData();
  for (const key in data) {
    let value = data[key as keyof IUpload];
    if (!value) continue;
    if (typeof value === 'number') value = `${value}`;
    if (key === 'files')
      (value as File[]).forEach((file) => formData.append(key, file));
    else if (typeof value === 'string') formData.append(key, value);
  }
  const response = await axios.post(REACT_APP_UPLOAD_URL!, formData);

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default upload;
