import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { COUNTRIES } from '../../../../lib/utils';
import { DocumentsBox } from '../Documents';
import InputResult from './InputResult';
import PersonalDataBox from './PersonalDataBox';
import { IStrapiFile, IUser } from '../../../../lib/interfaces';

interface IGeneralData {
  acceptedFiles: readonly string[];
  files: IStrapiFile[];
  user: IUser;
  loading: boolean;
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: IStrapiFile) => void;
}

const GeneralData = (props: IGeneralData) => {
  const { t } = useTranslation('PROFILE');
  const {
    acceptedFiles = [],
    files,
    user: _user,
    loading,
    onFileChange,
    onFileDelete,
  } = props;
  const user: IUser = { ..._user };
  for (const userKey in user) {
    const userValue = (user as any)[userKey];
    if ([null, ''].includes(userValue)) (user as any)[userKey] = '-';
  }
  const navigate = useNavigate();
  const {
    name = '',
    surname = '',
    email = '',
    vatNumber = '',
    fiscalCode = '',
    phone = '',
    fax = '',
    customerCode = '',
    companyName = '',
    address = '',
    hamlet = '',
    postalCode = '',
    municipality = '',
    province = '',
    country = '',
  } = user;
  const countryKey =
    COUNTRIES.find(({ value }) => value === country)?.name || country;

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="relative h-full">
          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP1_TITLE')}>
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP1_NAME')}
              text={name}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP1_SURNAME')}
              text={surname}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP1_EMAIL')}
              text={email}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP1_VAT')}
              text={vatNumber}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP1_FISCAL_CODE')}
              text={fiscalCode}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP1_PHONE')}
              text={phone}
            />
            <InputResult label={t('ACTIVATION_REQUEST:STEP1_FAX')} text={fax} />
          </PersonalDataBox>

          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP2_TITLE')}>
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP2_CODE')}
              text={customerCode}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP2_BUSINESS_NAME')}
              text={companyName}
            />
          </PersonalDataBox>

          <PersonalDataBox title={t('ACTIVATION_REQUEST:STEP3_TITLE')}>
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP3_ADDRESS')}
              text={address}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP3_FRACTION')}
              text={hamlet}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP3_CAP')}
              text={postalCode}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP3_CITY')}
              text={municipality}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP3_STATE_PROVINCE')}
              text={province}
            />
            <InputResult
              label={t('ACTIVATION_REQUEST:STEP3_NATION')}
              text={t(countryKey)}
            />
          </PersonalDataBox>

          <DocumentsBox
            acceptedFiles={acceptedFiles}
            files={files}
            title={t('ACTIVATION_REQUEST:STEP4_TITLE')}
            loading={loading}
            onFileChange={onFileChange}
            onFileDelete={onFileDelete}
          />

          <div className="flex justify-end">
            <PrimaryButton
              text={t('EDIT_BUTTON')}
              onClick={() => navigate('/profile/edit')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralData;
