import { FC, useEffect } from 'react';
import { ICartItem } from '../../lib/fetch/cartItems';
import { IOrderItem } from '../../lib/fetch/orderItems';
import { IAddress } from '../../lib/interfaces';
import { calculateCartTotals } from '../../lib/utils/cart';
import { calculateOrderTotals } from '../../lib/utils/order';
import SubtotalPresentational from './SubtotalPresentational';

export interface ITotals {
  subTotal: number;
  totalDiscount: number;
  totalTaxes: number;
  totalShipping: number;
  subTotalNet: number;
  total: number;
}

interface ISubtotal {
  cartItems?: ICartItem[];
  orderItems?: IOrderItem[];
  setTotalAmount?: (total: number) => void;
  shipping: number;
  shippingAddress: IAddress | null | undefined;
}

const Subtotal: FC<ISubtotal> = (props) => {
  const {
    cartItems,
    orderItems,
    setTotalAmount = () => {},
    shipping,
    shippingAddress,
  } = props;

  let totals;
  if (cartItems) {
    totals = calculateCartTotals(cartItems, shipping);
  } else {
    totals = calculateOrderTotals(orderItems!, shipping);
  }
  const { total } = totals;

  useEffect(() => {
    setTotalAmount(total || 0);
  }, [total]);

  return (
    <SubtotalPresentational shippingAddress={shippingAddress} totals={totals} />
  );
};

export default Subtotal;
