import { PlusIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import AddressForm from './AddressForm';
import { IAddress } from '../../../../../lib/interfaces';
import { FormProvider, useForm } from 'react-hook-form';
import { useAlert } from '../../../../../providers/AlertProvider';
export { default as AddressForm } from './AddressForm';

interface INewAddressBox {
  loading: boolean;
  onAddressCreate: (_: IAddress) => void;
}

const NewAddressBox = (props: INewAddressBox) => {
  const { loading = false, onAddressCreate = (_: IAddress) => {} } = props;
  const { t } = useTranslation('PROFILE');
  const [open, setOpen] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const cancelButtonRef = useRef(null);
  const formMethods = useForm<IAddress>();
  const { handleSubmit, reset: resetForm } = formMethods;

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        <PlusIcon className="h-8 w-8 mx-auto text-red-600" />
        <span className="mt-2 block text-lg font-medium text-gray-600">
          {t('NEW_ADDRESS')}
        </span>
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(false);
            setTimeout(resetForm, 500);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="leading-6 text-md font-bold text-gray-800"
                  >
                    {t('NEW_ADDRESS')}
                  </Dialog.Title>

                  <FormProvider {...formMethods}>
                    <AddressForm />
                  </FormProvider>
                </div>

                <div className="mt-6 flex justify-end">
                  <PrimaryButton
                    text={t('BTN_ADD_ADDRESS')}
                    loading={loading}
                    onClick={handleSubmit(
                      (formData) => {
                        onAddressCreate(formData);
                        setOpen(false);
                        resetForm();
                      },
                      () => {
                        dispatchAlertChange({
                          open: true,
                          message: t('VALIDATION_ERROR'),
                        });
                      }
                    )}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NewAddressBox;
