import { useTranslation } from 'react-i18next';

const StepsHeaderMobile = ({
  currentStep,
  steps,
}: {
  currentStep: number;
  steps: readonly string[];
}) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  return (
    <nav
      className="flex items-center justify-center my-8"
      aria-label="Progress"
    >
      <p className="text-sm font-medium">
        {t('MOBILE_HEADER_TITLE', {
          current: currentStep + 1,
          total: steps.length,
        })}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((stepName, index) => (
          <li key={stepName}>
            {currentStep > index ? (
              <span className="block w-2.5 h-2.5 bg-red-600 rounded-full">
                <span className="sr-only">{t(stepName)}</span>
              </span>
            ) : currentStep === index ? (
              <span
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-red-200" />
                </span>
                <span
                  className="relative block w-2.5 h-2.5 bg-red-600 rounded-full"
                  aria-hidden="true"
                />
                <span className="sr-only">{t(stepName)}</span>
              </span>
            ) : (
              <span className="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                <span className="sr-only">{t(stepName)}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepsHeaderMobile;
