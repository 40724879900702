import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { classNames } from '../../lib/utils';

interface IDragAndDrop {
  acceptedFiles: readonly string[];
  onFileChange: (_: File[]) => void;
}

const DragAndDrop = (props: IDragAndDrop) => {
  const { acceptedFiles = [], onFileChange = (_: File[]) => {} } = props;
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const onDrop = useCallback((files) => {
    onFileChange(files);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
      {...getRootProps({ onClick: (e) => e.preventDefault() })}
    >
      <div className="space-y-1 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div
          className={classNames(
            !isDragActive && 'flex',
            'text-sm text-gray-600'
          )}
        >
          <label
            htmlFor="file-upload"
            className="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
          >
            {!isDragActive && <span>{t('STEP4_SELECT_FILE')}</span>}
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
              {...getInputProps({
                accept: acceptedFiles.join(),
              })}
            />
          </label>
          <p className="pl-1">
            {t(isDragActive ? 'STEP4_DROP_FILE' : 'STEP4_DRAG_FILE')}
          </p>
        </div>
        <p className="text-xs text-gray-500">{t('STEP4_ACCEPTED_FILES')}</p>
      </div>
    </div>
  );
};

export default DragAndDrop;
