import axios, { AxiosResponse } from 'axios';
import { IOrder } from '.';
import qs from 'qs';
import { OrderStatus } from '../../enums';

const { REACT_APP_ORDERS_URL } = process.env;
const qsOptions = { encodeValuesOnly: true };

interface ISearchParams {
  page: number;
  pageSize: number;
  sort?: string | string[];
  userId: number;
  statuses?: OrderStatus[];
  minDate?: string | null;
  maxDate?: string | null;
}

interface ISearchOrdersResponse {
  data: IOrder[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

const list = async (token: string, params: ISearchParams) => {
  const { page, pageSize, sort, userId, statuses, minDate, maxDate } = params;

  const filters = {
    $and: [{ users_permissions_user: { id: userId } }],
  } as any;
  if (statuses) {
    filters.$and.push({ $or: statuses.map((status) => ({ status })) });
  }
  if (minDate) {
    filters.$and.push({ date: { $gte: minDate } });
  }
  if (maxDate) {
    filters.$and.push({ date: { $lte: maxDate } });
  }

  const pagination = { page, pageSize };
  const query = qs.stringify(
    {
      filters,
      pagination,
      populate: '*',
      sort,
    },
    qsOptions
  );

  const response = await axios.get<ISearchOrdersResponse>(
    `${REACT_APP_ORDERS_URL!}?${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
