import { memo } from 'react';
import { IOrder } from '../../lib/fetch/orders';
import { IPayment } from '../../lib/fetch/payments';
import { PaymentMethod, PaymentStatus } from '../../lib/enums';
import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';

export const getPendingWireTransfer = (
  order: IOrder
): Omit<IPayment, 'id'> | undefined => {
  const { attributes } = order || {};
  const { payments } = attributes || {};
  let { data } = payments || {};
  data = Array.isArray(data) ? data : [];
  return data.find((payment) => {
    const { attributes: paymentAttributes } = payment || {};
    const { provider, status } = paymentAttributes;
    return (
      provider === PaymentMethod.WireTransfer &&
      status === PaymentStatus.Processing
    );
  })?.attributes;
};

interface IWireTransferInfo {
  order?: IOrder;
  wireTransferData?: IWireTransferDataAttributes;
}

const WireTransferInfo = memo((props: IWireTransferInfo) => {
  const { order } = props;
  let { wireTransferData } = props;

  if (order && !wireTransferData) {
    const wireTransfer = getPendingWireTransfer(order);
    if (!wireTransfer) return null;

    const { paymentData } = wireTransfer;
    try {
      wireTransferData = JSON.parse(paymentData).wireTransferData;
    } catch (e) {
      return null;
    }
  }

  if (!wireTransferData) return null;

  const {
    bankAddressFirstLine,
    bankAddressSecondLine,
    bankCountry,
    bankName,
    beneficiary,
    iban,
    instructions,
  } = wireTransferData as IWireTransferDataAttributes;
  return (
    <div className="mt-2 w-96 rounded-md border border-gray-200 p-4 text-gray-500">
      <p className="mb-4 text-xs font-light">{instructions || ''}</p>
      <p className="text-sm font-normal">
        <span className="font-semibold">{beneficiary || ''}</span>
        <br />
        <span className="font-semibold">{iban || ''}</span>
        <br />
        {bankName || ''}
        <br />
        {bankAddressFirstLine || ''}
        <br />
        {bankAddressSecondLine || ''}
        <br />
        {bankCountry || ''}
      </p>
    </div>
  );
});

export default WireTransferInfo;
