import axios, { AxiosResponse } from 'axios';
import { IProduct } from './index';

const { REACT_APP_PRODUCTS_URL } = process.env;

const getOne = async (token: string, id: number, lang: string) => {
  const response = await axios.get<{ data: IProduct }>(
    `${REACT_APP_PRODUCTS_URL}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        populate: '*',
        locale: lang,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getOne;
