import { Fragment, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../lib/interfaces';
import { useAlert } from '../../../providers/AlertProvider';
import { Dialog, Transition } from '@headlessui/react';
import { AddressForm } from '../../Profile/components/Addresses/NewAddressBox';
import { PrimaryButton } from '../../../components/Buttons';

interface INewAddressTxt {
  loading: boolean;
  onAddressCreate: (_: IAddress) => void;
}

const NewAddressTxt = (props: INewAddressTxt) => {
  const { loading = false, onAddressCreate = (_: IAddress) => {} } = props;
  const [open, setOpen] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const cancelButtonRef = useRef(null);
  const formMethods = useForm<IAddress>();
  const { handleSubmit, reset: resetForm } = formMethods;
  const { t } = useTranslation('SHOPPING_CART');

  return (
    <>
      <div className="mt-8 text-center">
        <p className="text-sm text-gray-600">{t('NO_ADDRESS')}</p>
        <button
          onClick={() => setOpen(true)}
          className="text-base font-semibold text-red-500 underline underline-offset-2"
        >
          {t('ADD_NEW_ADDRESS_BTN')}
        </button>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(false);
            setTimeout(resetForm, 500);
          }}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-md font-bold leading-6 text-gray-800"
                  >
                    {t('PROFILE:NEW_ADDRESS')}
                  </Dialog.Title>

                  <FormProvider {...formMethods}>
                    <AddressForm />
                  </FormProvider>
                </div>

                <div className="mt-6 flex justify-end">
                  <PrimaryButton
                    text={t('PROFILE:BTN_ADD_ADDRESS')}
                    loading={loading}
                    onClick={handleSubmit(
                      (formData) => {
                        onAddressCreate(formData);
                        setOpen(false);
                        resetForm();
                      },
                      () => {
                        dispatchAlertChange({
                          open: true,
                          message: t('PROFILE:VALIDATION_ERROR'),
                        });
                      }
                    )}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NewAddressTxt;
