import axios, { AxiosResponse } from 'axios';
import { PaymentMethod } from '../../enums';

const { REACT_APP_PAYMENTS_URL } = process.env;

export interface IPaymentIntent {
  orderId: number;
  paymentMethod: PaymentMethod;
}

const createIntent = async (token: string, data: IPaymentIntent) => {
  const response = await axios.get(`${REACT_APP_PAYMENTS_URL!}/intent`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default createIntent;
