import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

const ReturnInfo = () => {
  const { t } = useTranslation('PROFILE');

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
          <h3 className="mb-6 text-2xl font-bold text-gray-800">
            {t('RETURN_INFO')}
          </h3>

          <div className="mt-6 bg-gray-50 py-4 px-6 text-center">
            <p
              className="text-normal text-gray-600"
              dangerouslySetInnerHTML={{ __html: t('RETURN_INFO_DESCRIPTION') }}
            />
            <a
              href="mailto:support@trevi.parts"
              target="_blank"
              rel="noreferrer"
              className="mx-auto mt-6 inline-block"
            >
              <PrimaryButton text={t('CONTACT_US')} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnInfo;
