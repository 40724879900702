import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { OrderStatus } from '../../enums';

const { REACT_APP_ORDERS_URL } = process.env;
const qsOptions = { encodeValuesOnly: true };

interface ICountParams {
  userId: number;
  statuses?: OrderStatus[];
  minDate?: string | null;
  maxDate?: string | null;
}

interface ICountOrdersResponse {
  meta: {
    pagination: {
      total: number;
    };
  };
}

const count = async (token: string, params: ICountParams) => {
  const { userId, statuses, minDate, maxDate } = params;

  const filters = {
    $and: [{ users_permissions_user: { id: userId } }],
  } as any;
  if (statuses) {
    filters.$and.push({ $or: statuses.map((status) => ({ status })) });
  }
  if (minDate) {
    filters.$and.push({ date: { $gte: minDate } });
  }
  if (maxDate) {
    filters.$and.push({ date: { $lte: maxDate } });
  }
  const query = qs.stringify(
    {
      filters,
      fields: ['id'],
    },
    qsOptions
  );

  const response = await axios.get<ICountOrdersResponse>(
    `${REACT_APP_ORDERS_URL!}?${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default count;
