import { useEffect, useState } from 'react';
import { useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import LoginPresentational from './LoginPresentational';
import { login } from '../../lib/fetch/auth';
import i18n from '../../i18n';

export interface ILoginForm {
  identifier: string;
  password: string;
  remember: boolean;
}

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const [{ appLoaded, isAuthenticated }, dispatchAuthChange] = useAuth();
  const [lang] = i18n.languages;

  useEffect(() => {
    setLoading(isAuthenticated && !appLoaded);
  }, [appLoaded, isAuthenticated]);

  const onLogin = async (formData: ILoginForm) => {
    const { identifier, password, remember } = formData;

    setLoading(true);
    const { error, data: loginResponse } = await login(
      {
        identifier,
        password,
      },
      lang
    );
    setLoading(false);
    const { jwt, user } = loginResponse || {};
    const { blocked, confirmed } = user || {};

    if (error || blocked || !confirmed)
      return dispatchAlertChange({
        open: true,
        message: 'LOGIN:ERROR',
      });

    dispatchAuthChange({
      type: AuthActionType.Login,
      token: jwt,
      remember,
      user,
    });
  };

  return <LoginPresentational loading={loading} onLogin={onLogin} />;
};

export default Login;
