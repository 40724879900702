import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRODUCT_DETAIL_PATHNAME } from '../../../App';
import { getThumbnail } from '../../../components/ProductsTable';
import AddCartField from '../../../components/ProductsTable/AddCartField';
import Spinner from '../../../components/Spinner';
import {
  MadeOrderBadge,
  OutProductionBadge,
  PiecesBadge,
  UnavailableBadge,
} from '../../../components/StockBadge';
import { HIDDEN_MANUFACTURERS_NAMES } from '../../../lib/constants';
import { IProduct } from '../../../lib/fetch/products';
import { currency } from '../../../lib/utils';
import {
  getTecdocManufacturerById,
  getTecdocManufacturerByName,
} from '../../../lib/utils/manufacturers';
import { useSettings } from '../../../providers/SettingsProvider';

interface ICrossReferencesProps {
  crossReferences: IProduct[];
  onAddToCart?: (product: IProduct, quantity: number) => void;
  loading?: boolean;
}

const CrossReferences = (props: ICrossReferencesProps) => {
  const { crossReferences, onAddToCart = () => {}, loading = false } = props;
  const { t } = useTranslation('PRODUCTS');
  const [{ tecdocManufacturers }] = useSettings();

  return loading ? (
    <div className="mx-auto">
      <Spinner
        style={{
          color: 'rgb(220 38 38)',
          width: 30,
          height: 30,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </div>
  ) : !!crossReferences.length ? (
    <div className="flex flex-col gap-4 md:flex-row">
      <div className="flex-1">
        <div className="text-sm">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {crossReferences.map((product, key) => (
                  <tr className="border-t" key={`crosses-${key}`}>
                    {/* <td className="py-2 pl-2">
                    <Disclosure.Button className="bg-white text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <ChevronRightIcon
                        className={`${
                          open ? 'rotate-90 transform' : ''
                        } h-4 w-4`}
                      />
                    </Disclosure.Button>
                  </td> */}
                    <td className="whitespace-nowrap py-2 pl-2 pr-4">
                      <div className="flex items-center">
                        {getThumbnail(
                          product.tecdoc?.images[0]?.imageURL3200 ||
                            product.attributes.thumbnail
                        )}
                        <Link
                          to={`${PRODUCT_DETAIL_PATHNAME}/${product.id}`}
                          className="ml-3"
                        >
                          <div className="text-sm font-medium text-gray-900">
                            {product.attributes.code}
                          </div>
                          <div className="text-sm font-normal text-gray-500">
                            {product.tecdoc?.genericArticles[
                              product.tecdoc?.genericArticles.length - 1
                            ]?.genericArticleDescription ||
                              product.attributes.name ||
                              product.attributes.family?.data.attributes.name}
                          </div>
                        </Link>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 tracking-wider">
                      {product.tecdoc?.dataSupplierId &&
                      getTecdocManufacturerById(
                        product.tecdoc?.dataSupplierId,
                        tecdocManufacturers
                      ) ? (
                        <img
                          className="h-auto w-20"
                          src={
                            getTecdocManufacturerById(
                              product.tecdoc?.dataSupplierId,
                              tecdocManufacturers
                            )!.dataSupplierLogo.imageURL800
                          }
                          alt=""
                        />
                      ) : product.attributes.manufacturer?.data.attributes
                          .name &&
                        getTecdocManufacturerByName(
                          product.attributes.manufacturer?.data.attributes.name,
                          tecdocManufacturers
                        ) ? (
                        <img
                          className="h-auto w-20"
                          src={
                            getTecdocManufacturerByName(
                              product.attributes.manufacturer?.data.attributes
                                .name,
                              tecdocManufacturers
                            )!.dataSupplierLogo.imageURL800
                          }
                          alt=""
                        />
                      ) : product.attributes.manufacturer?.data.attributes
                          .name &&
                        !HIDDEN_MANUFACTURERS_NAMES.includes(
                          `${product.attributes.manufacturer?.data.attributes.name}`.toUpperCase()
                        ) ? (
                        product.attributes.manufacturer.data.attributes.name
                      ) : (
                        product.attributes.manufacturer?.data.id || '-'
                      )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2">
                      <div className="text-sm text-yellow-700">
                        {/* TODO: handle delivery days */}
                        {/*{t('DELIVERY_TIME_DAYS', {*/}
                        {/*  days: 180,*/}
                        {/*})}*/}-
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
                      {!product.attributes.quantity &&
                        (product.attributes.dismissed ? (
                          <div>
                            <UnavailableBadge />
                            <OutProductionBadge />
                          </div>
                        ) : (
                          <MadeOrderBadge />
                        ))}
                      <PiecesBadge quantity={product.attributes.quantity} />
                      {/* TODO: handle warehouses */}
                      {/*<WarehouseModal />*/}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
                      {product.attributes.price
                        ? currency(product.attributes.price)
                        : '-'}
                      {/* TODO: handle promotions */}
                      {/*<PromotionField />*/}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-right text-sm font-medium">
                      <AddCartField
                        onAdd={(quantity) => onAddToCart(product, quantity)}
                        product={product}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{t('DETAILS_CURRENTLY_NOT_AVAILABLE')}</>
  );
};

export default CrossReferences;
