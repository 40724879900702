import axios, { AxiosResponse } from 'axios';

const { REACT_APP_ORDER_SHIPPING_ADDRESSES_URL } = process.env;

export interface IOrderShippingAddressCreate {
  title: string;
  name: string;
  phone?: string;
  addressFirstLine: string;
  addressSecondLine?: string;
  postalCode: string;
  municipality: string;
  province?: string;
  country: string;
  users_permissions_user: number;
}

export interface IOrderShippingAddressCreateResult {
  id: number;
  attributes: IOrderShippingAddressCreate;
}

const create = async (
  token: string,
  orderShippingAddress: IOrderShippingAddressCreate
) => {
  const response = await axios.post<{
    data: IOrderShippingAddressCreateResult;
  }>(
    REACT_APP_ORDER_SHIPPING_ADDRESSES_URL!,
    { data: orderShippingAddress },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
