import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import thankYouLottie from '../../lotties/thank-you-success.json';

const ThankYouRegistration = () => {
  const { t } = useTranslation('THANKYOU');
  return (
    <div className="min-h-screen flex bg-gray-50">
      <div className="flex justify-center items-center flex-col w-full p-12">
        <Lottie
          loop
          animationData={thankYouLottie}
          play
          style={{ width: 150, height: 150 }}
        />

        <h1 className="text-green-700 font-bold text-4xl mb-8 text-center">
          {t('REGISTRATION_SUCCESS_TITLE')}
        </h1>

        <p
          className="mb-8 text-sm text-gray-600 text-center"
          dangerouslySetInnerHTML={{
            __html: t('REGISTRATION_SUCCESS_DESCRIPTION'),
          }}
        />
        <Link to="/login">
          <PrimaryButton text={t('FORGOT:BACK_LOGIN')} />
        </Link>
      </div>
    </div>
  );
};

export default ThankYouRegistration;
