import { FC } from 'react';
// import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getThumbnail } from '.';
import { PRODUCT_DETAIL_PATHNAME } from '../../App';
import { HIDDEN_MANUFACTURERS_NAMES } from '../../lib/constants';
import { ICartItem } from '../../lib/fetch/cartItems';
import { currency } from '../../lib/utils';
import {
  getTecdocManufacturerById,
  getTecdocManufacturerByName,
} from '../../lib/utils/manufacturers';
import { useSettings } from '../../providers/SettingsProvider';
export { default as Pagination } from './Pagination';

interface ICheckoutProductsTable {
  cartItems: ICartItem[];
}

const CheckoutProductsTable: FC<ICheckoutProductsTable> = (props) => {
  const { cartItems = [] } = props;
  const [{ tecdocManufacturers }] = useSettings();

  // const { t } = useTranslation('SHOPPING_CART');
  return (
    <div className="mb-6 flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="relative px-4 py-3 text-left"
                    colSpan={3}
                  >
                    {/* TODO: handle Delivery dates */}
                    {/* <span className="mr-2 text-sm font-normal text-green-500">
                      {t('DELIVERY_DATE')}:
                    </span>
                    <span className="mr-10 text-base text-green-500">
                      16/12/2021
                    </span>
                    <span className="text-xs font-light text-gray-500">
                      Se effettui l'ordine prima delle 18:00
                    </span> */}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {cartItems.map((cartItem, index) => (
                  <tr className="border-t" key={index}>
                    <td className="whitespace-nowrap px-4 py-4">
                      <div className="flex items-center">
                        <div className="mr-6 text-sm font-medium text-gray-700">
                          <span>{cartItem.quantity} x</span>
                        </div>
                        {getThumbnail(
                          cartItem.product.tecdoc?.images[0]?.imageURL3200 ||
                            cartItem.product.thumbnail
                        )}
                        <Link
                          to={`${PRODUCT_DETAIL_PATHNAME}/${cartItem.product.id}`}
                          className="ml-3"
                        >
                          <div className="text-sm font-medium text-gray-900">
                            {cartItem.product.code}
                          </div>
                          <div className="text-sm font-normal text-gray-400">
                            {cartItem.product.tecdoc?.genericArticles[
                              cartItem.product.tecdoc?.genericArticles.length -
                                1
                            ]?.genericArticleDescription ||
                              cartItem.product.name ||
                              cartItem.product.family.name}
                          </div>
                          {cartItem.product.tecdoc?.dataSupplierId &&
                          getTecdocManufacturerById(
                            cartItem.product.tecdoc?.dataSupplierId,
                            tecdocManufacturers
                          ) ? (
                            <img
                              className="h-auto w-20"
                              src={
                                getTecdocManufacturerById(
                                  cartItem.product.tecdoc?.dataSupplierId,
                                  tecdocManufacturers
                                )!.dataSupplierLogo.imageURL800
                              }
                              alt=""
                            />
                          ) : cartItem.product.manufacturer.name &&
                            getTecdocManufacturerByName(
                              cartItem.product.manufacturer.name,
                              tecdocManufacturers
                            ) ? (
                            <img
                              className="h-auto w-20"
                              src={
                                getTecdocManufacturerByName(
                                  cartItem.product.manufacturer.name,
                                  tecdocManufacturers
                                )!.dataSupplierLogo.imageURL800
                              }
                              alt=""
                            />
                          ) : cartItem.product.manufacturer?.name &&
                            !HIDDEN_MANUFACTURERS_NAMES.includes(
                              `${cartItem.product.manufacturer?.name}`.toUpperCase()
                            ) ? (
                            cartItem.product.manufacturer.name
                          ) : (
                            cartItem.product.manufacturer?.id || '-'
                          )}
                        </Link>
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-4 py-4 text-right text-lg font-semibold text-gray-700">
                      <span className="">
                        {cartItem.price
                          ? currency(cartItem.price * cartItem.quantity)
                          : '-'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutProductsTable;
