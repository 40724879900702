import { FC, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { quantityInputProps } from '../../../lib/utils';
import { IProduct } from '../../../lib/fetch/products';

interface IEditField {
  cartItem: ICartItem;
  onQuantityChange: (cartItem: ICartItem, quantity: number) => void;
  onRemove: (cartItem: ICartItem) => void;
}

const EditField: FC<IEditField> = (props) => {
  const { cartItem, onQuantityChange = () => {}, onRemove = () => {} } = props;
  const { product, quantity } = cartItem;
  const {
    max: maxQuantity,
    min: minQuantity,
    disabled: isUnavailable,
  } = quantityInputProps({ attributes: product } as unknown as IProduct);
  const { handleSubmit, register, setValue, watch } = useForm({
    defaultValues: { quantity },
  });
  const onSubmit = handleSubmit(
    useDebouncedCallback(
      ({ quantity: newQuantity }) =>
        onQuantityChange(cartItem, +newQuantity || quantity),
      800
    )
  );

  useEffect(() => {
    setValue('quantity', quantity);
  }, [quantity]);

  useEffect(
    () =>
      watch((formData, { name, type }) => {
        name === 'quantity' && type === 'change' && onSubmit();
      }).unsubscribe,
    [watch]
  );

  return (
    <form className="flex items-center justify-end" onSubmit={onSubmit}>
      <input
        type="number"
        className="mr-2 block w-full w-20 rounded-md border-transparent bg-gray-100 py-2 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
        max={maxQuantity}
        min={minQuantity}
        step="1"
        disabled={isUnavailable}
        required
        {...register('quantity', {
          max: maxQuantity,
          min: minQuantity,
          disabled: isUnavailable,
          required: true,
        })}
      />
      <button
        type="button"
        className="bg-trasparent flex items-center justify-center rounded-full border border-transparent text-sm font-normal text-red-600 shadow-sm hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        onClick={() => onRemove(cartItem)}
      >
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </form>
  );
};

export default EditField;
