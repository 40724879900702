import axios, { AxiosResponse } from 'axios';
import { OrderStatus } from '../../enums';

const { REACT_APP_ORDERS_URL } = process.env;

export interface IOrderCreate {
  users_permissions_user: number;
  date: Date;
  order_shipping_address: number;
  order_billing_address: number;
  subtotal: number;
  discount: number;
  taxes: number;
  shipping: number;
  total: number;
  status: OrderStatus.NotPaid;
  order_items: number[];
}

const create = async (token: string, order: IOrderCreate, lang = 'en') => {
  const response = await axios.post(
    REACT_APP_ORDERS_URL!,
    { data: order },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        locale: lang,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
