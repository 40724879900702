import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/solid';
export { default as StepsHeaderMobile } from './StepsHeaderMobile';

const StepsHeader = ({
  currentStep,
  steps,
}: {
  currentStep: number;
  steps: readonly string[];
}) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  return (
    <nav aria-label="Progress" className="my-8">
      <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((stepName, index) => (
          <li key={index} className="relative md:flex-1 md:flex">
            {currentStep > index ? (
              <span className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-red-600 rounded-full">
                    <CheckIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {t(stepName)}
                  </span>
                </span>
              </span>
            ) : currentStep === index ? (
              <span
                className="px-6 py-4 flex items-center text-sm font-medium"
                aria-current="step"
              >
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-red-600 rounded-full">
                  <span className="text-red-600">
                    {`${index + 1}`.padStart(2, '0')}
                  </span>
                </span>
                <span className="ml-4 text-sm font-medium text-red-600">
                  {t(stepName)}
                </span>
              </span>
            ) : (
              <span className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                    <span className="text-gray-500">
                      {`${index + 1}`.padStart(2, '0')}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">
                    {t(stepName)}
                  </span>
                </span>
              </span>
            )}

            {index !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="hidden md:block absolute top-0 right-0 h-full w-5"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepsHeader;
