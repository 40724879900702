import { useTranslation } from 'react-i18next';

const TermsTxt = () => {
  const { t } = useTranslation('TERMS');
  return (
    <div className="border border-gray-300 rounded-xl px-6 py-6 mb-6">
      <h3 className="text-2xl font-bold text-gray-800 mb-6">
        {t('COMPLETE_TITLE')}
      </h3>

      <h4 className="text-md font-semibold text-gray-700 mb-3">
        INFORMATIVA SUI COOKIE
      </h4>
      <p className="mb-6 font-light text-sm text-gray-600">
        Per rendere il nostro sito più facile ed intuitivo facciamo uso dei
        cookie. I cookie sono piccole porzioni di dati che ci consentono di di
        capire come gli utenti navigano attraverso il nostro sito. Utilizziamo i
        dati raccolti grazie ai cookie per rendere l'esperienza di navigazione
        più piacevole e più efficiente in futuro.
        <br />
        <br />
        I cookie non registrano alcuna informazione personale su un utente e gli
        eventuali dati identificabili non verranno memorizzati. Se si desidera
        disabilitare l'uso dei cookie è necessario personalizzare le
        impostazioni del proprio browser impostando la cancellazione di tutti i
        cookie o attivando un messaggio di avviso quando i cookie vengono
        memorizzati. Per procedere senza modificare l'applicazione dei cookie è
        sufficiente continuare con la navigazione.
        <br />
        Visita AboutCookies.org per ulteriori informazioni sui cookie e su come
        influenzano la tua esperienza di navigazione.
        <br />I tipi di cookie che utilizziamo
      </p>

      <h5 className="text-sm font-semibold text-gray-700 mb-3 underline">
        Cookie indispensabili
      </h5>
      <p className="mb-6 font-light text-sm text-gray-600">
        Questi cookie sono essenziali al fine di consentire di utilizzare a
        pieno le sue caratteristiche, come ad esempio compilare un modulo di
        contatto. Senza questi cookie alcune funzionalità del sito non possono
        essere usufruite.
      </p>

      <h5 className="text-sm font-semibold text-gray-700 mb-3 underline">
        Performance cookies
      </h5>
      <p className="mb-6 font-light text-sm text-gray-600">
        Questi cookie raccolgono informazioni su come gli utenti utilizzano un
        sito web, ad esempio, quali sono le pagine più visitate. Questi cookie
        non raccolgono informazioni che identificano un visitatore. Tutte le
        informazioni raccolte tramite cookie sono aggregate e quindi anonime.
        Vengono utilizzati solo per migliorare il funzionamento di un sito web.
        <br />
        Utilizzando il nostro sito, l’utente accetta che tali cookie possono
        essere installati sul proprio dispositivo.
      </p>

      <h5 className="text-sm font-semibold text-gray-700 mb-3 underline">
        Come gestire i cookie sul vostro device
      </h5>
      <p className="mb-6 font-light text-sm text-gray-600">
        Per informazioni su come gestire (abilitare/disabilitare) i cookie sul
        vostro computer, potete consultare i seguenti link, a seconda del vostro
        browser:
        <br />
        <br />
        Chrome:{' '}
        <a
          href="https://support.google.com/chrome/answer/95647?hl=en"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://support.google.com/chrome/answer/95647?hl=en
        </a>
        <br />
        Safari:{' '}
        <a
          href="https://support.apple.com/kb/PH17191?viewlocale=en_US"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://support.apple.com/kb/PH17191?viewlocale=en_US
        </a>
        <br />
        Firefox:{' '}
        <a
          href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
        </a>
        <br />
        Microsoft Explorer:{' '}
        <a
          href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9
        </a>
        <br />
        Ulteriori informazioni sui cookies è consultabile nel sito del Garante
        della Privacy:{' '}
        <a
          href="http://www.garanteprivacy.it/cookie"
          target="_blank"
          rel="noreferrer"
          className="text-red-600 hover:text-red-800"
        >
          http://www.garanteprivacy.it/cookie
        </a>
      </p>

      <h5 className="text-sm font-semibold text-gray-700 mb-3 underline">
        Google Analytics
      </h5>
      <p className="mb-6 font-light text-sm text-gray-600">
        Questo sito web utilizza Google Analytics, un servizio di analisi web
        fornito da Google , Inc. ( “Google”) . Google Analytics utilizza dei
        “cookies” , che sono file di testo depositati sul Vostro computer per
        consentire al sito web di analizzare come gli utenti utilizzano il sito
        . Le informazioni generate dal cookie sull'utilizzo del sito web
        (compreso il vostro indirizzo IP in forma anonima) verranno trasmesse e
        depositate presso i server di Google. Google utilizzerà queste
        informazioni allo scopo di compilare report sulle attività del sito per
        gli amministratori dello stesso e fornire altri servizi relativi alle
        attività del sito web e all'utilizzo di internet. Google può anche
        trasferire queste informazioni a terzi ove ciò sia imposto dalla legge o
        laddove tali terzi trattino le suddette informazioni per conto di
        Google. Potete rifiutarvi di usare i cookies selezionando l'impostazione
        appropriata sul vostro browser, ma si prega di notare che in questo modo
        non si può essere in grado di utilizzare tutte le funzionalità di questo
        sito web . Utilizzando il presente sito web, voi acconsentite al
        trattamento dei vostri dati da parte di Google per le modalità ed i fini
        sopraindicati .
        <br />
        Si può impedire a Google il rilevamento di un cookie che viene generato
        a causa di e legato al vostro utilizzo di questo sito web e
        l'elaborazione di tali dati scaricando e installando questo plugin per
        il browser: Google Analytics Opt-out Browser Add-on
        <br />
        <br />
        In ogni caso, vi segnaliamo che in questo sito è stato predisposto uno
        specifico parametro che forza Google a raccogliere l'indirizzo IP solo
        in forma anonima
      </p>
    </div>
  );
};

export default TermsTxt;
