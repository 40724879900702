const ShippingStepTitle = ({
  text,
  stepNumber,
}: {
  stepNumber: any;
  text: string;
}) => {
  return (
    <div
      className="pb-4 mb-6 flex items-center text-sm font-medium border-b"
      aria-current="step"
    >
      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-red-600 rounded-full">
        <span className="text-red-600">{stepNumber}</span>
      </span>
      <span className="ml-4 text-lg font-semibold text-gray-900">{text}</span>
    </div>
  );
};

export default ShippingStepTitle;
