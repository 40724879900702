import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import InputPresentational from '../Form/InputPresentational';
import SelectPresentational from '../Form/SelectPresentational';
import { COUNTRIES } from '../../lib/utils';
import { FC } from 'react';
interface IStep3 {
  isEditing?: boolean;
}

const Step3: FC<IStep3> = ({ isEditing = false }) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const { register } = useFormContext();

  return (
    <div className="border border-gray-300 rounded-md px-6 py-6">
      <h3 className="text-2xl font-bold text-gray-900 mb-6">
        {t('STEP3_TITLE')}
      </h3>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mb-6">
        <InputPresentational
          label={t('STEP3_ADDRESS')}
          id="address"
          {...register('address')}
          autoComplete="street-address"
        />
        <InputPresentational
          label={t('STEP3_FRACTION')}
          id="hamlet"
          {...register('hamlet')}
          autoComplete="address-level3"
        />
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <InputPresentational
          label={t('STEP3_CAP')}
          id="postalCode"
          {...register('postalCode')}
          autoComplete="postal-code"
        />
        <InputPresentational
          label={t('STEP3_CITY')}
          id="municipality"
          {...register('municipality')}
          autoComplete="address-level2"
        />
        <InputPresentational
          label={t('STEP3_STATE_PROVINCE')}
          id="province"
          {...register('province')}
          autoComplete="address-level1"
        />
        <SelectPresentational
          label={t('STEP3_NATION')}
          id="country"
          options={[{ name: '-', value: '' }, ...COUNTRIES]}
          {...register('country')}
          autoComplete="country-name"
        />
      </div>
    </div>
  );
};

export default Step3;
