import axios, { AxiosResponse } from 'axios';

const { REACT_APP_ORDER_ITEMS_URL } = process.env;

export interface IOrderItemCreate {
  productId: number;
  productCode: string;
  productEan?: string;
  productClassId?: number;
  productClassName?: string;
  productFamilyId?: number;
  productFamilyName?: string;
  productManufacturerId?: number;
  productManufacturerName?: string;
  productManufacturerLogo?: string;
  productThumbnail?: string;
  productName?: string;
  productStandardDescription?: string;
  productUsageDescription?: string;
  productAssemblyDescription?: string;
  quantity: number;
  price: number;
  discount: number;
  taxes: number;
  shipping: number;
}

export interface IOrderItemCreateResult {
  id: number;
  attributes: {
    order: number;
    productId: number;
    productCode: string;
    productEan?: string;
    productClassId?: number;
    productClassName?: string;
    productFamilyId?: number;
    productFamilyName?: string;
    productManufacturerId?: number;
    productManufacturerName?: string;
    productManufacturerLogo?: string;
    productThumbnail?: string;
    productName?: string;
    productStandardDescription?: string;
    productUsageDescription?: string;
    productAssemblyDescription?: string;
    quantity: number;
    price: number;
    discount: number;
    taxes: number;
    shipping: number;
  };
}

const create = async (token: string, orderItem: IOrderItemCreate) => {
  const response = await axios.post<{ data: IOrderItemCreateResult }>(
    REACT_APP_ORDER_ITEMS_URL!,
    { data: orderItem },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
