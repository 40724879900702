import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useAlert } from '../../providers/AlertProvider';
import BackButton from '../../components/Buttons/BackButton';
import StepsHeader, { StepsHeaderMobile } from '../../components/StepsHeader';
import { Step1, Step2, Step3, Step4, Step5 } from '../../components/Signup';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import LightPrimaryButton from '../../components/Buttons/LightPrimaryButton';
import { IUser } from '../../lib/interfaces';

interface IActivationRequestPresentational {
  loading: boolean;
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: File) => void;
  onRegister: (_: IUser) => void;
  acceptedFiles: readonly string[];
  files: File[];
}

const STEPS = Object.freeze([Step1, Step2, Step3, Step4, Step5]);

export const STEPS_TITLES = Object.freeze([
  'STEP1_TITLE',
  'STEP2_TITLE',
  'STEP3_TITLE',
  'STEP4_TITLE',
  'STEP5_TITLE',
]);

const ActivationRequestPresentational = (
  props: IActivationRequestPresentational
) => {
  const {
    loading = false,
    acceptedFiles = [],
    files = [],
    onFileChange = (_: File[]) => {},
    onFileDelete = (_: File) => {},
    onRegister = (_: IUser) => {},
  } = props;
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const formMethods = useForm<IUser>();
  const { handleSubmit } = formMethods;
  const [, dispatchAlertChange] = useAlert();
  const [currentStep, setCurrentStep] = useState(0);
  const isFirstStep = !currentStep;
  const isLastStep = currentStep === STEPS.length - 1;

  const getStep = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      case 3:
        return (
          <Step4
            acceptedFiles={acceptedFiles}
            files={files}
            onFileChange={onFileChange}
            onFileDelete={onFileDelete}
          />
        );
      case 4:
        return <Step5 />;
    }
  };

  return (
    <>
      <div className="min-h-screen py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="mb-10">
              <BackButton
                text={t('COMMON:BACK')}
                to="/login"
                disabled={loading}
              />
            </div>
            <img
              className="h-7 w-auto mx-auto"
              src="../images/trevi-logo-red.svg"
              alt="Trevi Ecommerce"
              title="Trevi Ecommerce"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 text-center">
              {t('TITLE')}
            </h2>
            <p
              className="mt-2 text-sm text-gray-600 text-center"
              dangerouslySetInnerHTML={{ __html: t('DESCRIPTION') }}
            />

            <div className="hidden lg:block">
              <StepsHeader currentStep={currentStep} steps={STEPS_TITLES} />
            </div>
            <div className="block lg:hidden">
              <StepsHeaderMobile
                currentStep={currentStep}
                steps={STEPS_TITLES}
              />
            </div>

            <div>
              <FormProvider {...formMethods}>
                {getStep(currentStep)}
              </FormProvider>

              <div className="flex items-center justify-between mt-8">
                <LightPrimaryButton
                  text={t('COMMON:PREV')}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  disabled={isFirstStep || loading}
                />
                <PrimaryButton
                  loading={loading}
                  disabled={loading}
                  text={t(isLastStep ? 'BTN_REQUEST' : 'COMMON:NEXT')}
                  onClick={handleSubmit(
                    (formData) =>
                      isLastStep
                        ? onRegister(formData)
                        : setCurrentStep(currentStep + 1),
                    () =>
                      dispatchAlertChange({
                        open: true,
                        message: t('VALIDATION_ERROR'),
                      })
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivationRequestPresentational;
