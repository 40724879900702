import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Location,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryParamProvider } from 'use-query-params';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import './i18n';
import { AlertProvider } from './providers/AlertProvider';
import { AuthProvider } from './providers/AuthProvider';
import { ShoppingCartOverlayProvider } from './providers/ShoppingCartOverlayProvider';
import App from './App';
import Alert from './components/Alert';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { SettingsProvider } from './providers/SettingsProvider';

const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;
const PAYPAL_OPTIONS = Object.freeze({
  'client-id': REACT_APP_PAYPAL_CLIENT_ID!,
  currency: 'EUR',
});

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <AlertProvider>
          <SettingsProvider>
            <ShoppingCartOverlayProvider>
              <HashRouter>
                <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
                  <PayPalScriptProvider options={PAYPAL_OPTIONS}>
                    <App />
                    <Alert />
                  </PayPalScriptProvider>
                </QueryParamProvider>
              </HashRouter>
            </ShoppingCartOverlayProvider>
          </SettingsProvider>
        </AlertProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
