const AddressButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex items-center px-2.5 py-1.5 text-sm font-medium rounded text-red-600 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
    >
      {text}
    </button>
  );
};

export default AddressButton;
