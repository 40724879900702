import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import {
  create as createAddress,
  update as updateAddress,
  remove as removeAddress,
} from '../../../../lib/fetch/addresses';
import { me, update as updateProfile } from '../../../../lib/fetch/auth';
import { IAddress } from '../../../../lib/interfaces';
import { AlertType, useAlert } from '../../../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../../../providers/AuthProvider';
import AddressesPresentational from './AdressesPresentational';

const Addresses = () => {
  const { t } = useTranslation('PROFILE');
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const { uuid } = user || {};
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const lang = i18n.languages[0];

  useEffect(() => {
    uuid && setAddresses(user?.addresses || []);
  }, [user]);

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!, lang);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const onAddressCreate = async (newAddress: IAddress) => {
    setLoading(true);

    const { error: createAddressError, data: createdAddress } =
      await createAddress(token!, newAddress);

    if (createAddressError) {
      let { data: { error: { message = undefined } = {} } = {} } =
        createAddressError;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    const addressesIds = (user!.addresses || [])
      .map((address) => address.id)
      .concat(createdAddress.data.id);

    const { error: updateProfileError } = await updateProfile(token!, {
      id: user!.id,
      addresses: addressesIds,
    });

    if (updateProfileError) {
      let { data: { error: { message = undefined } = {} } = {} } =
        updateProfileError;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    refetchProfile();
    setLoading(false);
    dispatchAlertChange({
      message: t('ADDRESS_CREATE_SUCCESS'),
      open: true,
      type: AlertType.Success,
    });
  };

  const onAddressDelete = async (deletedAddress: IAddress) => {
    setLoading(true);

    const { error: deleteAddressError } = await removeAddress(
      token!,
      deletedAddress
    );

    if (deleteAddressError) {
      let { data: { error: { message = undefined } = {} } = {} } =
        deleteAddressError;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    const addressesIds = (user!.addresses || [])
      .map((address) => address.id)
      .filter((addressId) => addressId !== deletedAddress.id);

    const { error: updateProfileError } = await updateProfile(token!, {
      id: user!.id,
      addresses: addressesIds,
    });

    if (updateProfileError) {
      let { data: { error: { message = undefined } = {} } = {} } =
        updateProfileError;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    refetchProfile();
    setLoading(false);
    dispatchAlertChange({
      message: t('ADDRESS_DELETE_SUCCESS'),
      open: true,
      type: AlertType.Success,
    });
  };

  const onAddressEdit = async (editedAddress: IAddress) => {
    setLoading(true);

    const { error: updateAddressError } = await updateAddress(
      token!,
      editedAddress
    );

    if (updateAddressError) {
      let { data: { error: { message = undefined } = {} } = {} } =
        updateAddressError;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    refetchProfile();
    setLoading(false);
    dispatchAlertChange({
      message: t('ADDRESS_UPDATE_SUCCESS'),
      open: true,
      type: AlertType.Success,
    });
  };

  return (
    <AddressesPresentational
      loading={loading}
      addresses={addresses}
      onAddressCreate={onAddressCreate}
      onAddressDelete={onAddressDelete}
      onAddressEdit={onAddressEdit}
    />
  );
};

export default Addresses;
