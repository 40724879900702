import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { FC, Fragment } from 'react';
import { AVAILABLE_LANGUAGES } from '../../lib/constants';
import { Language } from '../../lib/enums';
import { classNames } from '../../lib/utils';

interface ILanguageDropdown {
  selectedLanguage?: Language;
  onChangeLanguage?: (language: Language) => void;
}

const LanguageOptions = AVAILABLE_LANGUAGES.map((lang) => ({
  value: lang,
  img: `../images/flags/${lang}.png`,
}));

const LanguageDropdown: FC<ILanguageDropdown> = (props) => {
  const { selectedLanguage, onChangeLanguage = () => {} } = props;

  const selectedOption =
    LanguageOptions.find((v) => v.value === selectedLanguage) ||
    LanguageOptions[0];

  return (
    <Listbox value={selectedOption.value} onChange={onChangeLanguage}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left focus:outline-none sm:text-sm">
              <span className="flex items-center">
                <img
                  src={selectedOption.img}
                  alt=""
                  className="h-6 w-6 flex-shrink-0 rounded-full"
                />
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {LanguageOptions.map((lang, key) => (
                  <Listbox.Option
                    key={key}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-200 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={lang.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={lang.img}
                            alt=""
                            className="h-6 w-6 flex-shrink-0 rounded-full"
                          />
                        </div>

                        {selected ? (
                          <span
                            className={
                              'absolute inset-y-0 right-0 flex items-center pr-4 text-red-600'
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default LanguageDropdown;
